import { PRIMARY_COLOR, SECONDARY_COLOR, TERNARY_COLOR } from "../../../styles";

const page = {
  width: "100%",
  padding: "3rem 2rem",
};

const header = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2rem",
};

const heading = {
  fontSize: "2.2rem",
  fontWeight: "bold",
};

const categoryDropdown = {
  marginTop: "1rem",
  width: "250px",
};

const nameOfExerciseBox = {
  marginTop: "2rem",
};

const nameOfExerciseInputField = {
  width: "400px",
};

const addDescriptionBox = {
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem",
};

const descriptionInputField = {
  width: "400px",
};

const addDescriptionBtn = {
  background: PRIMARY_COLOR,
  color: TERNARY_COLOR,
  textTransform: "capitalize",
  padding: "0.3rem 0.8rem",
  borderRadius: "8px",
  display: "inline-block",
  maxWidth: "120px",
  marginTop: "1rem",
  "&:hover": {
    background: PRIMARY_COLOR,
    color: TERNARY_COLOR,
    opacity: "0.9",
  },
};

const addVideoBox = {
  marginTop: "3rem",
};

const createExerciseBtn = {
  fontSize: "1rem",
  color: PRIMARY_COLOR,
  background: SECONDARY_COLOR,
  borderRadius: "8px",
  maxHeight: "50px",
  margin: "4rem auto",
  padding: "0.5rem 4rem",
  textTransform: "capitalize",
  "&:hover": {
    color: PRIMARY_COLOR,
    background: SECONDARY_COLOR,
    opacity: "0.9",
  },
};

const predefinedNotesBox = {
  margin: "2rem 0",
};

export {
  page,
  header,
  heading,
  categoryDropdown,
  nameOfExerciseBox,
  nameOfExerciseInputField,
  addDescriptionBox,
  descriptionInputField,
  addDescriptionBtn,
  addVideoBox,
  createExerciseBtn,
  predefinedNotesBox,
};
