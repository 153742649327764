import { Box } from "@mui/material";
//hooks

//style
import { StyledSidebar } from "./style";

type Props = {
  children: any;
  isOpen: boolean;
  isMobile: boolean;
};

export const Sidebar = ({ children, isOpen, isMobile }: Props) => {
  return (
    <StyledSidebar isMobile={isMobile} isOpen={isOpen} component="aside">
      {children}
    </StyledSidebar>
  );
};
