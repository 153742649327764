//Components
import { useState } from "react";
import { AdminSidebar } from "./adminSidebar";
//Contexts
import { Box } from "@mui/material";
//Assets
import { ArrowLeftIcon, MenuIcon } from "../assets";
//Utils
import { isMobile } from "../utils";
import { BackButton } from "../components";

type Props = {
  children: any;
  adminNavItems?: { title: string; path: string }[];
  userNavItems?: { title: string; path: string }[];
};

export const Layout = ({ children, adminNavItems, userNavItems }: Props) => {
  const isMobileDevice = isMobile();
  const [isOpen, setIsOpen] = useState<boolean>(isMobileDevice ? false : true);

  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        background: "rgb(249, 249, 249)",
        maxWidth: "100%",
        "@media(max-width:600px)": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {isMobileDevice && (
        <Box onClick={toggleSidebar} sx={{ padding: "2rem 0 0 1rem" }}>
          <MenuIcon fill="#001D22" width="26" height="26" />
        </Box>
      )}

      <AdminSidebar
        isMobile={isMobileDevice}
        isOpen={isOpen}
        navItems={adminNavItems || userNavItems || []}
        toggleSidebar={toggleSidebar}
      />
      <Box
        sx={{
          position: "absolute",
          top: "2rem",
          right: "20px",
          cursor: "pointer",
        }}
      >
        <BackButton />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          transition: "margin-left 0.3s ease",
          overflowX: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
