import { PRIMARY_COLOR, TERNARY_COLOR } from "../../styles";

const trainingItem = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
  padding: "1rem",
  borderRadius: "8px",
  boxShadow: " 0 0 0 0.5px rgba(22,22,23,.05), 0 4px 10px rgba(22,22,23,.1)",
};

const accordionStyle = {
  boxShadow: "none !important",
  border: "none !important",
  borderBottom: "none !important",
  width: "100%",
};

const accordionSummary = {
  display: "flex",
  alignItems: "center",
};

const iconBox = {
  display: "flex",
  marginLeft: "auto",
};

const titleStyle = {
  fontSize: "1.3rem",
  color: PRIMARY_COLOR,
  fontWeight: "bold",
  marginLeft: "0.2rem",
};

const openVideoBtn = {
  color: TERNARY_COLOR,
  background: PRIMARY_COLOR,
  backgroundImage:
    "linear-gradient( 180deg, rgb(255 255 255 / 16%) 0%, rgb(255 255 255 / 0%) 100% )",
  borderRadius: "8px",
  maxHeight: "50px",
  marginTop: "0.8rem",
  "&:hover": {
    background: PRIMARY_COLOR,
  },
};

const addTrainingBtn = {
  color: "#161617",
  border: "1px solid #161617",
  borderRadius: "8px",
  display: "inline-block",
  textTransform: "capitalize",
  padding: "0.2rem 1.2rem",
};
const addedTrainingBtn = {
  background: "#00A76F",
  color: "white",
  border: "1px solid transparent",
  borderRadius: "8px",
  display: "inline-block",
  textTransform: "capitalize",
  padding: "0.2rem 1.2rem",
};
const addBtnBox = {
  marginRight: "1rem",
};

const fieldInput = {
  marginTop: "0.6rem",
  width: "300px",
};

export {
  trainingItem,
  accordionStyle,
  titleStyle,
  accordionSummary,
  openVideoBtn,
  iconBox,
  addTrainingBtn,
  addBtnBox,
  addedTrainingBtn,
  fieldInput,
};
