import { DIM_GREY_COLOR } from "../../styles/colorPallete";

const logoBox = {
  maxWidth: "80%",
  margin: "2rem auto",
  display: "flex",
  justifyContent: "center",
  "@media(max-width:600px)": {
    margin: "0rem auto",
  },
};

const adminSidebarWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alingItems: "center",
  padding: "2rem",
  textAlign: "center",
};

const navItem = {
  margin: "0.5rem",
  padding: "0.8rem 0.7rem",
  color: DIM_GREY_COLOR,
  fontSize: "1rem",
  borderRadius: "8px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

const sidebarFooter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export { logoBox, adminSidebarWrapper, navItem, sidebarFooter };
