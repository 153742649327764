type Props = {
  width?: string;
  height?: string;
};

export const EditIcon = ({ width = "24", height = "24" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
    >
      <path d="M209-199h40l335-336-40-40-335 336v40Zm567-393L601-766l28-29q37-38 88.5-38.5T807-797l21 21q31 29 29 67t-29 65l-52 52Zm-57 58L289-104H113v-175l430-430 176 175Zm-154-21-21-20 40 40-19-20Z" />
    </svg>
  );
};
