import { MAX_MOBILE_WIDTH, MIN_DESKTOP_WIDTH } from "../config";

const width = window.innerWidth;

export const isMobile = () => {
  return width <= MAX_MOBILE_WIDTH;
};
export const isDesktop = () => {
  return width >= MIN_DESKTOP_WIDTH;
};
