const infoBox = {
  marginTop: "2rem",
};

const dayStyle = {
  fontSize: "2rem",
  fontWeight: "bold",
};

const dateStyle = {
  fontSize: "1.1rem",
  fontWeight: "500",
  marginLeft: "2rem",
};

const dot = {
  width: "5px",
  height: "5px",
  background: "#471aff",
  borderRadius: "50%",
  marginRight: "0.3rem",
};

const contentItem = {
  display: "flex",
  alignItems: "center",
};

export { infoBox, dayStyle, dateStyle, dot, contentItem };
