const showcaseImage = require("../../assets/images/showcase.jpg");

const mainContent = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",

  width: "90%",
  maxWidth: "1200px",
  margin: "2rem auto",
};

const contactFormTextBox = {
  width: "40%",
  paddingTop: "3rem",
  paddingLeft: "2rem",
};

const iconsBoxWrapper = {
  display: "flex",
  width: "150px",
  marginTop: "0.5rem",
  alignItems: "center",
  justifyContent: "space-between",
};

const contactFormTitle = {
  fontSize: "4rem",
  lineHeight: "4rem",
  fontWeight: "bold",
  marginBottom: "2rem",
};

const showcaseFAQ = {
  position: "relative",
  width: "100%",
  height: "500px",
  background: `url(${showcaseImage}) center center`,
  backgroundSize: "cover",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "::after": {
    content: '""',
    zIndex: "-1",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(16, 16, 17, 0.85)",
  },
};

const showcaseContent = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const showcaseDescription = {
  minWidth: "60%",
  width: "600px",
  marginTop: "0.5rem",
  color: "white",
  textAlign: "center",
};

const heading = {
  color: "white",
  fontSize: "4.3rem",
  fontWeight: "bold",
};

export {
  mainContent,
  contactFormTextBox,
  iconsBoxWrapper,
  contactFormTitle,
  showcaseContent,
  showcaseDescription,
  heading,
  showcaseFAQ,
};
