//PATHS
import { HOME_PAGE_PATH, USER_HISTORY_OVERVIEW_PAGE, EXERCISES } from "./paths";
//ICONS
import { UsersIcon, SupportIcon, ExercisesIcon, CalendarIcon } from "../assets";

export const PLAN_TRENINGA = "Plan treninga";
export const KLIJENTI = "Klijenti";
const VEZBE = "Vežbe";

//DATA

export const ADMIN_NAV_ITEMS = [
  { title: KLIJENTI, path: HOME_PAGE_PATH, icon: UsersIcon },
  { title: VEZBE, path: EXERCISES, icon: ExercisesIcon },
];

export const USER_NAV_ITEMS_HOME_PAGE = [
  { title: PLAN_TRENINGA, path: HOME_PAGE_PATH, icon: ExercisesIcon },
  { title: "Istorija", path: USER_HISTORY_OVERVIEW_PAGE, icon: CalendarIcon },
];
