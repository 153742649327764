const HOME_PAGE_PATH = "/";
const CONTACT_PAGE_PATH = "/kontakt";
const FAQ_PAGE_PATH = "/pitanja";
const LOGIN_PAGE_PATH = "/login";
const SURVEY_PAGE_PATH = "/anketa";

//User
const TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH = "/trening";
const USER_HISTORY_OVERVIEW_PAGE = "/istorija";
const USER_SUPPORT_PAGE_PATH = "/podrska";
const USER_ANSWER_PATH = "/odgovori";

//Admin
const CUSTOMER = "/korisnik";
const EXERCISES = "/vezbe";
const STATISTICS = "/statistika";
const ADMIN_ANSWER_PATH = "/odgovor";
const EDIT_PATH = "/izmeni";

//Variables
const TRAINING = "trening";
const HISTORY = "istorija";
const ADD = "dodaj";

export {
  HOME_PAGE_PATH,
  CONTACT_PAGE_PATH,
  FAQ_PAGE_PATH,
  LOGIN_PAGE_PATH,
  TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH,
  USER_HISTORY_OVERVIEW_PAGE,
  CUSTOMER,
  EXERCISES,
  STATISTICS,
  TRAINING,
  HISTORY,
  SURVEY_PAGE_PATH,
  USER_SUPPORT_PAGE_PATH,
  USER_ANSWER_PATH,
  ADMIN_ANSWER_PATH,
  ADD,
  EDIT_PATH,
};
