import { PRIMARY_COLOR, TERNARY_COLOR } from "../../../../../styles";

const resumeSection = {
  margin: "2rem 0",
};

const textFieldBox = {
  display: "flex",
  alignItems: "center",
  marginTop: "0.5rem",
};

const inputField = {
  "& .MuiInputBase-root": {
    height: "40px",
  },
};

const addLabelBtn = {
  marginLeft: "0.5rem",
  background: PRIMARY_COLOR,
  color: TERNARY_COLOR,
  "&:hover": {
    background: PRIMARY_COLOR,
    color: TERNARY_COLOR,
    opacity: "0.9",
  },
};

const listLabel = {
  marginTop: "1rem",
  display: "flex",
  flexWrap: "wrap",
  width: "90%",
  maxWidth: "90%",
};

export { resumeSection, textFieldBox, addLabelBtn, listLabel, inputField };
