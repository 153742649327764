type Props = {
  width?: string;
  height?: string;
};

export const TopArrowIcon = ({
  width = "48",
  height = "48",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
    >
      <path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" />
    </svg>
  );
};
