import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
//Config
import { ADMIN_ROLE, TOKEN, USER_ROLE, FULLUSER } from "../../config";
import { AuthService, UserServices } from "../../services";
import { useNavigate } from "react-router-dom";

type ReturnedValues = {
  role: string;
  login: (email: string, password: string) => Promise<boolean>;
  user: any;
};
const AuthenticationContext = createContext<ReturnedValues>({
  role: "",
  login: async (email: string, password: string) => false, // default implementation
  user: {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

type Props = {
  children: any;
};

export const AuthenticationContextProvider = ({ children }: Props) => {
  const [user, setUser] = useState<any>(() => {
    const token = localStorage.getItem(TOKEN);
    return token ? jwtDecode(token) : null;
  });
  const [fullUser, setFullUser] = useState<any>(() => {
    const fulluser = localStorage.getItem(FULLUSER);
    if (fulluser) {
      const user = JSON.parse(fulluser);
      return user;
    }
    return {};
  });

  const userRole = fullUser?.role || USER_ROLE;

  const login = async (email: string, password: string) => {
    try {
      const res: any = await AuthService.login({
        email: email,
        password: password,
      });
      const token = res.data.accessToken;

      const decoded: any = jwtDecode(token);

      const responseGetUserById = await UserServices.getById(decoded?.id);
      setFullUser(responseGetUserById);
      setUser(responseGetUserById);

      localStorage.setItem(FULLUSER, JSON.stringify(responseGetUserById));
      localStorage.setItem(TOKEN, token);
      return true;
    } catch (err) {
      console.error("Login error", err);
      return false;
    }
  };

  return (
    <AuthenticationContext.Provider value={{ role: userRole, login, user }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
