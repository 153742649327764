import { Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
//Style
import { logoBox, adminSidebarWrapper, sidebarFooter } from "./style";
//Components
import { Sidebar } from "../../components/sidebar";
import { LogoutButton } from "../../components/logoutButton/LogoutButton";
import { UserInfoBox } from "../../components";
import { NavItem } from "./NavItem";
//Context
import { useAuthenticationContext } from "../../context";
//Assets
import { PaperIcon, ViewIcon, ArrowLeftIcon, WhiteNewLogo } from "../../assets";
//Config
import { CUSTOMER, HISTORY } from "../../config";
import { ADMIN_ROLE, SURVEY_PAGE_PATH } from "../../config";

type Props = {
  navItems: { title: string; path: string; icon?: any }[];
  isOpen: boolean;
  toggleSidebar: () => void;
  isMobile: boolean;
};

export const AdminSidebar = ({
  navItems,
  isOpen,
  toggleSidebar,
  isMobile,
}: Props) => {
  const location = useLocation();
  const { id } = useParams();
  const { role, user } = useAuthenticationContext();
  const containsCustomer = location.pathname.includes(CUSTOMER);
  const isAdmin = role === ADMIN_ROLE;

  const userEmail = user?.email;
  const userFullName = user.fullName;

  return (
    <Sidebar isOpen={isOpen} isMobile={isMobile}>
      <Box>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ padding: "1rem" }} onClick={toggleSidebar}>
              <ArrowLeftIcon fill="#f4f4f4" />
            </Box>
          </Box>
        )}
        <Box sx={logoBox}>
          <WhiteNewLogo />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: isMobile ? "53vh" : "60vh",
            justifyContent: "space-between",
          }}
        >
          <Box sx={adminSidebarWrapper}>
            {navItems.map((item) => {
              return (
                <NavItem
                  key={item.title}
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                />
              );
            })}
          </Box>
          {containsCustomer && isAdmin ? (
            <Box sx={adminSidebarWrapper}>
              <NavItem
                title="Istorija"
                path={`${CUSTOMER}/${HISTORY}/${id}`}
                icon={ViewIcon}
              />
              <NavItem
                title="Anketa"
                path={`${CUSTOMER}${SURVEY_PAGE_PATH}/${id}`}
                icon={PaperIcon}
              />
            </Box>
          ) : null}
        </Box>

        <Box sx={sidebarFooter}>
          <UserInfoBox email={userEmail} fullName={userFullName} />
          <LogoutButton />
        </Box>
      </Box>
    </Sidebar>
  );
};
