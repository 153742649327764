export const CUSROMER_TABLE_HEAD = [
  { id: 1, label: "Ime i prezime", align: "left" },
  { id: 2, label: "Paket", align: "left" },
  { id: 3, label: "Početak programa", align: "left" },
  { id: 4, label: "Kraj programa", align: "left" },
  { id: 7, label: "Ukupno plaćenih meseci", align: "left" },
  { id: 5, label: "Cena paketa za ovaj mesec", align: "left" },
  { id: 6, label: "Ukupno", align: "left" },
  { id: 7, label: "Podešavanja", align: "left" },
];
