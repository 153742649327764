import { Box, Typography } from "@mui/material";
//Style
import { predefinedNotesStyle, predefinedNoteBox, dot } from "./style";

type Props = {
  predefinedNotes: string;
};

export const PredefinedNotesTraining = ({ predefinedNotes }: Props) => {
  return (
    <Box sx={predefinedNotesStyle}>
      {predefinedNotes.split(";").map((note) => (
        <Box sx={predefinedNoteBox} key={note}>
          <Box sx={dot}></Box>
          <Typography key={note}>{note}</Typography>
        </Box>
      ))}
    </Box>
  );
};
