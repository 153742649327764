const PRIMARY_COLOR = "#001D22"; //rich black
const SECONDARY_COLOR = "#67cccc"; //tiffany blue
const TERNARY_COLOR = "#eef5f5"; //anty flesh white

const GREY_COLOR = "#B2BBB8";

//other colors
const DIM_GREY_COLOR = "#677374";
const MIDNIGHT_GREEN_COLOR = "#012e33";
const AZURE_WEB_COLOR = "#ddebec";

export {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERNARY_COLOR,
  GREY_COLOR,
  DIM_GREY_COLOR,
  MIDNIGHT_GREEN_COLOR,
  AZURE_WEB_COLOR,
};
