export const getDateBasedOnTimestamp = (timestamp: string) => {
  if (!timestamp) return;
  const dateObj = new Date(timestamp);

  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};
