import { Box, Typography } from "@mui/material";
//Components
import { ExcerciseItem } from "./components";
//Style
import { trainingDetailSection, partOfTrainingStyle } from "./style";
import { ExerciseType } from "../../types";

type Props = {
  data?: ExerciseType[] | any;
  selectedCategory?: string;
};

export const UserTrainingPartDetails = ({ data, selectedCategory }: Props) => {
  console.log(data, "DATA");
  console.log(selectedCategory, "selected cat");
  return (
    <Box sx={trainingDetailSection}>
      <Typography variant="h2" sx={partOfTrainingStyle}>
        {selectedCategory}
      </Typography>

      <Box>
        {data?.map((exercise: any) => {
          return (
            <ExcerciseItem
              description={exercise.description}
              video={exercise.videoURL}
              predefinedNotes={exercise.defaultDescription}
            />
          );
        })}
      </Box>
    </Box>
  );
};
