import { Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
//Components
import { Layout } from "../../../layout";
import { InfoBox } from "../customerDetailPage/components/infoBox";
//Style
import {
  userHistoryOverivewPage,
  pageHeader,
  monthItemBox,
  monthTitle,
  heading,
  itemList,
} from "./style";
//Config
import { CUSTOMER, HISTORY } from "../../../config/paths";
//Hooks
import { useGetUserById } from "../../../hooks";
//Utils
import {
  getMonthBasedOnTimestamp,
  getDateBasedOnTimestamp,
} from "../../../utils";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerAccountHistory = ({ navItems }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useGetUserById(id!);

  const onClickHandler = (month: string) => {
    navigate(`${CUSTOMER}/${HISTORY}/${user?.id}/${month.toLowerCase()}`);
  };

  const months = user?.trainings.map((training) => {
    return getMonthBasedOnTimestamp(training.date);
  });
  const uniqueMonths = months?.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return (
    <Layout userNavItems={navItems}>
      <Box sx={userHistoryOverivewPage}>
        <Box sx={pageHeader}>
          <Typography sx={heading} variant="h1">
            Istorija
          </Typography>
        </Box>
        <InfoBox
          fullName={user?.fullName}
          selectedPackage={user?.packageType}
          startDate={getDateBasedOnTimestamp(user?.startDate!)}
          endDate={getDateBasedOnTimestamp(user?.endDate!)}
        />
        <Box sx={itemList} component="ul">
          {uniqueMonths?.map((month) => {
            const trainings = user?.trainings;
            const filteredTrainingsForSpecificMonth = trainings?.map(
              (training) => {
                return getMonthBasedOnTimestamp(training.date) === month;
              }
            );

            return (
              <Box
                sx={monthItemBox}
                key={month}
                onClick={() => onClickHandler(month)}
              >
                <Typography sx={monthTitle}>{month}</Typography>
                <Typography>
                  Broj treninga: {filteredTrainingsForSpecificMonth?.length}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Layout>
  );
};
