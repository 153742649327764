import { useState } from "react";
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
//Assets
import { CloseIcon } from "../../assets";
//Style
import {
  popupContent,
  iconBox,
  textFieldBox,
  textField,
  title,
  addBtn,
  btnContainer,
  closeBtn,
} from "./style";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (description: string) => void;
};
export const AddDescriptionPopup = ({ isOpen, onClose, onAdd }: Props) => {
  const [value, setValue] = useState<string>("");

  const onChangeHandler = (e: any) => {
    const textValue = e.target.value;
    setValue(textValue);
  };

  const onAddHandler = () => {
    onAdd(value);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={popupContent}>
        <Box sx={iconBox} onClick={onClose}>
          <CloseIcon width="30" height="30" />
        </Box>
        <Typography variant="h4" sx={title}>
          Dodaj opis
        </Typography>
        <Box sx={textFieldBox}>
          <TextField
            value={value}
            onChange={(e) => onChangeHandler(e)}
            sx={textField}
            placeholder="Opis za konkretnu vežbu i konkretnog klijenta..."
            multiline
            rows={4}
          />
        </Box>
        <Box sx={btnContainer}>
          <Button sx={closeBtn} variant="outlined" onClick={onClose}>
            Izadji
          </Button>
          <Button variant="contained" sx={addBtn} onClick={onAddHandler}>
            Dodaj
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
