import { createTheme } from "@mui/material/styles";
import { primary, secondary } from "./pallete";

const theme = createTheme({
  palette: { primary, secondary },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: "12px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});

export default theme;
