import { PRIMARY_COLOR, TERNARY_COLOR } from "../../../styles";

const page = {
  width: "100%",
  padding: "3rem 2rem",
};

const pageHeader = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const calendarBox = {
  marginTop: "1.5rem",
};

const heading = {
  fontSize: "2.2rem",
  fontWeight: "bold",
};

const trainingBarBox = {
  margin: "2rem 0",
};

const createBtnBox = {
  marginTop: "3rem",
};

const createTrainingBtn = {
  background: PRIMARY_COLOR,
  color: TERNARY_COLOR,
  borderRadius: "8px",
  display: "inline-block",
  padding: "0.4rem 1.5rem",
  "&:hover": {
    background: PRIMARY_COLOR,
    color: TERNARY_COLOR,
    opacity: "0.9",
  },
};

export {
  page,
  pageHeader,
  calendarBox,
  heading,
  trainingBarBox,
  createBtnBox,
  createTrainingBtn,
};
