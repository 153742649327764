const dropzoneBox = {
  width: "270px",
  height: "230px",
  maxWidth: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "3px solid grey",
  borderRadius: "8px",
  cursor: "pointer",
};

export { dropzoneBox };
