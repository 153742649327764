import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
//Components
import { Calendar } from "../../../components";
import { Layout } from "../../../layout";
import { BackButton } from "../../../components";
import { SelectorTrainingBar } from "../../user/userTrainingDetailPage/components";
import { ListOfTrainingItems } from "../components";
import { SearchBar } from "../../../components";
import { ResumeTrainingSection } from "./components";
//Style
import {
  page,
  pageHeader,
  calendarBox,
  heading,
  trainingBarBox,
  createBtnBox,
  createTrainingBtn,
} from "./style";
//Config
import { VEZBE_ISTEZANJA, VEZBE_ZAGREVANJA, TRENING } from "../../../config";
//Types
import { ExerciseType } from "../../../types/exercise";
import { UserType } from "../../../types";
//Services
import {
  ExerciseServices,
  UserServices,
  TrainingServices,
} from "../../../services";
import dayjs from "dayjs";

type Props = {
  navItems: { title: string; path: string }[];
};

const data = [VEZBE_ZAGREVANJA, TRENING, VEZBE_ISTEZANJA];

export const CustomerAddTrainingPage = ({ navItems }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTraining, setSelectedTraining] = useState<string>(data[0]);
  const [trainingSummary, setTrainingSummart] = useState<string>();

  const today = dayjs().format();
  const [selectedDate, setSelectedDate] = useState<any>(today);
  const [exercises, setExercises] = useState<ExerciseType[]>();

  //Ovu su vezbe koje su dodate useru
  const [addedExercisesIdsToUser, setAddedExercisesIdsToUser] = useState<any>(
    []
  );
  const [user, setUser] = useState<UserType>();

  useEffect(() => {
    try {
      ExerciseServices.getAll().then((response) => {
        setExercises(response);
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    try {
      UserServices.getById(id!).then((response) => setUser(response));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const onSelectTrainingHandler = useCallback(
    (partOfTraining: string) => {
      setSelectedTraining(partOfTraining);
    },
    [selectedTraining]
  );

  const filteredData = exercises?.filter(
    (item) => item.category === selectedTraining
  );

  const onAddExerciseToUserHandler = (exercise: any) => {
    setAddedExercisesIdsToUser((prevState: any) => [...prevState, exercise]);
  };

  const onAddTrainingSummaryHandler = (trainingSummaryList: string[]) => {
    setTrainingSummart(trainingSummaryList?.join(";"));
  };

  const onRemoveExerciseToUserHandler = (exerciseId: string) => {
    const filteredExercises = addedExercisesIdsToUser.filter(
      (exercise: any) => exercise?.id !== exerciseId
    );
    setAddedExercisesIdsToUser(filteredExercises);
  };

  const onChangeSelectedDateHandler = (value: any) => {
    setSelectedDate(value);
  };

  const onCreateTrainingHandler = () => {
    const training = {
      date: selectedDate,
      trainingSummary: trainingSummary,
      exercises: addedExercisesIdsToUser,
      userId: user?.id,
    };
    try {
      TrainingServices.create(training);
      //Odradi navigate samo kada je uspesno, kada nije error snack
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout adminNavItems={navItems}>
      <Box sx={page}>
        <Box sx={pageHeader}>
          <Typography sx={heading}>Kreiraj trening</Typography>
          <BackButton />
        </Box>
        <Box sx={calendarBox}>
          <Typography>Izaberi datum</Typography>
          <Calendar
            value={selectedDate}
            onChange={onChangeSelectedDateHandler}
          />
        </Box>

        <ResumeTrainingSection
          onAddTrainingSummaryHandler={onAddTrainingSummaryHandler}
        />

        <Box sx={trainingBarBox}>
          <SelectorTrainingBar
            data={data}
            selectedTraining={selectedTraining}
            onClick={onSelectTrainingHandler}
          />
        </Box>
        <SearchBar label="Pretraži..." />
        <ListOfTrainingItems
          data={filteredData}
          onAddExerciseToUserHandler={onAddExerciseToUserHandler}
          onRemoveExerciseToUserHandler={onRemoveExerciseToUserHandler}
        />
        <Box sx={createBtnBox}>
          <Button
            sx={createTrainingBtn}
            variant="contained"
            onClick={onCreateTrainingHandler}
          >
            Kreiraj trening
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};
