import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
//Components
import { VideoPopup } from "./VideoPopup";
//Style
import {
  exerciseItem,
  titleStyle,
  predefinedNotesStyle,
  predefinedNoteItem,
} from "./style";
import { InfoIcon, PlayIcon } from "../../../assets";
import { ExerciseItemFooter } from "./ExerciseItemFooter";

type Props = {
  description?: string;
  video: string;
  predefinedNotes: string;
};
export const ExcerciseItem = ({
  description,
  video,
  predefinedNotes,
}: Props) => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const predefinedNotesArray = predefinedNotes?.split(";");

  const onPopupToggle = useCallback(() => {
    setIsOpenPopup((prevState) => !prevState);
  }, []);

  return (
    <>
      <VideoPopup isOpen={isOpenPopup} onClose={onPopupToggle} />
      <Box sx={exerciseItem}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "16px 16px 0 16px",
          }}
        >
          <Typography variant="h3" sx={titleStyle}>
            {description}
          </Typography>
          <Box
            onClick={onPopupToggle}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlayIcon />
          </Box>
        </Box>
        <Box sx={predefinedNotesStyle}>
          {predefinedNotesArray?.map((note) => (
            <Box sx={predefinedNoteItem}>
              <Box>
                <InfoIcon />
              </Box>
              <Typography sx={{ lineHeight: "1.1rem" }} key={note}>
                {note}.
              </Typography>
            </Box>
          ))}
        </Box>
        <ExerciseItemFooter />
      </Box>
    </>
  );
};
