import { Box, Typography } from "@mui/material";
//Style
import { infoBox, dayStyle, dateStyle, dot, contentItem } from "./style";
//Utils
import {
  getDateBasedOnTimestamp,
  getDayBasedOnTimestamp,
} from "../../../../../utils";

type Props = {
  day?: string;
  date?: string;
  content?: string;
};

export const InfoBox = ({ day, date, content }: Props) => {
  const displayedDate = getDateBasedOnTimestamp(date!);
  const displayedDay = getDayBasedOnTimestamp(date!);

  const labelsArray = content?.split(";");

  return (
    <Box sx={infoBox}>
      <Typography sx={dayStyle} variant="h1">
        {displayedDay}
      </Typography>
      <Typography sx={dateStyle}>({displayedDate})</Typography>
      <Box component="ul">
        {labelsArray?.map((item) => (
          <Box sx={contentItem}>
            <Box sx={dot}></Box>
            <Typography component="li" key={item}>
              {item}.
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
