import { useState, useEffect } from "react";
//Services
import { UserServices } from "../services";
//Types
import { UserType } from "../types";

export const useGetAllUsers = () => {
  const [users, setUsers] = useState<UserType[] | []>([]);

  useEffect(() => {
    UserServices.getAll().then((response) => setUsers(response));
  }, []);

  return { users };
};
