import { Box, Dialog } from "@mui/material";
//Assets
import { CloseIcon } from "../../../assets";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export const VideoPopup = ({ isOpen, onClose }: Props) => {
  const onClickHandler = () => {
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={onClickHandler}>
      <Box onClick={onClickHandler}>
        <CloseIcon width="30" height="30" />
      </Box>
      <Box>Video</Box>
    </Dialog>
  );
};
