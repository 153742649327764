import { useState, useEffect } from "react";
//Services
import { UserServices } from "../services";
//Types
import { UserType } from "../types";

export const useGetUserById = (id: string) => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    UserServices.getById(id).then((response) => setUser(response));
  }, []);

  return { user };
};
