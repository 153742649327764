import { Typography, Box } from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { DayCard } from "../../../components";
//Style
import { userHomePage, heading, daysList } from "./style";
//Hooks
import { useGetTrainingsByUserId } from "../../../hooks";

type Props = {
  navItems: { title: string; path: string }[];
};

export const UserHomePage = ({ navItems }: Props) => {
  const { trainings } = useGetTrainingsByUserId();

  return (
    <Layout userNavItems={navItems}>
      <Box sx={userHomePage}>
        <Typography sx={heading} variant="h1">
          Program
        </Typography>
        <Box sx={daysList}>
          {trainings.map((training: any) => {
            return (
              <DayCard
                trainingId={training.id}
                key={training.id}
                date={training.date}
                trainingSummary={training.trainingSummary}
              />
            );
          })}
        </Box>
      </Box>
    </Layout>
  );
};
