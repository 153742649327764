export const NewLogo = () => {
  return (
    <svg
      width="145"
      height="40"
      viewBox="0 0 145 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_41_2101)">
        <path
          d="M3.15537 10.2383H14.1874V3.45496H8.67136C8.57483 3.45496 8.37163 3.46904 8.0619 3.49718C7.75203 3.52532 7.39404 3.59569 6.98764 3.7084C6.58123 3.82098 6.15274 3.99407 5.70215 4.22738C5.25156 4.4607 4.83713 4.78267 4.45888 5.19301C4.08076 5.60335 3.76892 6.11642 3.5235 6.73194C3.27808 7.34745 3.15537 8.09384 3.15537 8.97095V10.2383ZM17.3256 17.6252H14.1874V13.3765H3.15537V17.6252H0.0171509V8.97095C0.0171509 7.70756 0.234424 6.54492 0.668972 5.48276C1.10352 4.42059 1.70693 3.50717 2.47949 2.74277C3.25191 1.97838 4.16519 1.38284 5.21933 0.956459C6.27348 0.529932 7.42415 0.31674 8.67136 0.31674H15.7444C15.9617 0.31674 16.1669 0.356985 16.36 0.437479C16.5532 0.51783 16.7222 0.630548 16.867 0.775351C17.0118 0.920153 17.1245 1.08916 17.2048 1.28237C17.2853 1.47544 17.3256 1.68061 17.3256 1.89788V17.6252Z"
          fill="#001D22"
        />
        <path
          d="M38.2671 6.13448C38.2671 6.85061 38.1786 7.49033 38.0015 8.05364C37.8245 8.61695 37.5852 9.11383 37.2834 9.5443C36.9816 9.97476 36.6336 10.3429 36.2393 10.6487C35.845 10.9545 35.4327 11.2059 35.0021 11.4031C34.5716 11.6002 34.1371 11.7431 33.6986 11.8316C33.2601 11.9201 32.8476 11.9643 32.4614 11.9643L39.0154 17.6252H34.1632L27.6212 11.9643H25.3642V8.82606H32.4614C32.8557 8.79397 33.2158 8.71137 33.5417 8.57867C33.8676 8.44583 34.1492 8.26683 34.3866 8.04154C34.624 7.81624 34.807 7.54465 34.9358 7.22676C35.0646 6.90901 35.1289 6.54483 35.1289 6.13448V4.15496C35.1289 3.97793 35.1068 3.84312 35.0624 3.75066C35.0183 3.65807 34.962 3.58968 34.8936 3.54549C34.825 3.50117 34.7527 3.47499 34.6763 3.46697C34.5998 3.45895 34.5294 3.45487 34.4651 3.45487H24.109V17.6252H20.9708V1.89793C20.9708 1.68066 21.011 1.47548 21.0914 1.28227C21.1719 1.0892 21.2825 0.920198 21.4233 0.775396C21.5642 0.630594 21.7311 0.517876 21.9243 0.437384C22.1174 0.356892 22.3266 0.316786 22.5519 0.316786H34.4651C35.165 0.316786 35.7565 0.443436 36.2393 0.696875C36.7221 0.950453 37.1144 1.2682 37.4161 1.6504C37.7179 2.03274 37.9351 2.44505 38.068 2.88762C38.2007 3.33019 38.2671 3.74461 38.2671 4.13089V6.13448Z"
          fill="#001D22"
        />
        <path
          d="M55.5514 13.2196C55.5514 12.8093 55.4871 12.4411 55.3583 12.1152C55.2296 11.7893 55.0485 11.5097 54.8152 11.2764C54.5818 11.0429 54.3001 10.8639 53.9703 10.7392C53.6403 10.6144 53.2702 10.5521 52.8598 10.5521H45.7868V7.38979H51.4476C51.858 7.38979 52.2281 7.32746 52.558 7.20264C52.888 7.07796 53.1696 6.89896 53.4029 6.6655C53.6363 6.43219 53.8153 6.15257 53.94 5.82666C54.0648 5.50075 54.1272 5.13263 54.1272 4.72228V4.13083C54.1272 3.68024 53.9099 3.45495 53.4754 3.45495H44.5315V14.4869H54.8876C54.9439 14.4869 55.0123 14.483 55.0928 14.4748C55.1732 14.4668 55.2456 14.4427 55.3101 14.4025C55.3744 14.3622 55.4307 14.2939 55.4789 14.1973C55.5272 14.1006 55.5514 13.9679 55.5514 13.7989V13.2196ZM58.6896 13.8231C58.6896 14.2093 58.6232 14.6237 58.4905 15.0663C58.3577 15.5088 58.1404 15.9193 57.8387 16.2974C57.537 16.6757 57.1447 16.9915 56.6618 17.2449C56.179 17.4984 55.5876 17.6252 54.8876 17.6252H42.9745C42.7492 17.6252 42.5399 17.5849 42.3468 17.5044C42.1536 17.4241 41.9867 17.3133 41.8459 17.1726C41.705 17.0317 41.5944 16.8647 41.5139 16.6716C41.4334 16.4786 41.3933 16.2693 41.3933 16.044V1.89787C41.3933 1.68059 41.4334 1.47542 41.5139 1.28235C41.5944 1.08914 41.705 0.920279 41.8459 0.775335C41.9867 0.630533 42.1536 0.517815 42.3468 0.437464C42.5399 0.35697 42.7492 0.316725 42.9745 0.316725H53.4754C53.8616 0.316725 54.2761 0.385115 54.7186 0.521896C55.1612 0.658676 55.5735 0.87792 55.9557 1.17977C56.338 1.48147 56.6558 1.87381 56.9092 2.35662C57.1628 2.83929 57.2895 3.43074 57.2895 4.13083V4.72228C57.2895 5.27757 57.197 5.87493 57.012 6.51465C56.8268 7.15437 56.5291 7.75989 56.1187 8.33122C56.4727 8.54849 56.8047 8.80995 57.1145 9.11574C57.4243 9.42153 57.6959 9.77558 57.9292 10.1779C58.1626 10.5802 58.3477 11.0349 58.4845 11.5419C58.6212 12.0488 58.6896 12.608 58.6896 13.2196V13.8231Z"
          fill="#001D22"
        />
        <path
          d="M78.2793 16.044C78.2793 16.2693 78.2391 16.4785 78.1586 16.6716C78.0781 16.8648 77.9655 17.0317 77.8207 17.1726C77.6758 17.3133 77.5069 17.424 77.3137 17.5044C77.1206 17.5849 76.9154 17.6251 76.6982 17.6251H69.6251C68.9572 17.6251 68.2814 17.5528 67.5973 17.4079C66.9134 17.2631 66.2515 17.0397 65.6119 16.738C64.9722 16.4363 64.3706 16.052 63.8074 15.5854C63.2441 15.1186 62.7512 14.5654 62.3287 13.9257C61.9063 13.286 61.5744 12.5537 61.333 11.7289C61.0916 10.9042 60.9709 9.98482 60.9709 8.97092V0.31671H64.1091V8.97092C64.1091 9.85606 64.2318 10.6044 64.4772 11.216C64.7227 11.8276 65.0345 12.3385 65.4126 12.7489C65.7909 13.1592 66.2053 13.4812 66.6559 13.7145C67.1065 13.9478 67.535 14.1209 67.9414 14.2335C68.3476 14.3462 68.7058 14.4166 69.0155 14.4447C69.3254 14.4728 69.5286 14.4869 69.6251 14.4869H75.1411V0.31671H78.2793V16.044Z"
          fill="#001D22"
        />
        <path
          d="M97.5672 3.45493H90.6511V17.6251H87.5128V3.45493H80.5847V0.31671H97.5672V3.45493Z"
          fill="#001D22"
        />
        <path
          d="M103.035 17.6252H99.8967V0.316755H103.035V17.6252Z"
          fill="#001D22"
        />
        <path
          d="M124.001 16.2854C124.001 16.5107 123.958 16.72 123.874 16.913C123.789 17.1062 123.677 17.2731 123.536 17.414C123.395 17.5547 123.228 17.6655 123.035 17.7458C122.842 17.8263 122.637 17.8665 122.419 17.8665C122.226 17.8665 122.031 17.8304 121.834 17.7579C121.637 17.6856 121.462 17.5688 121.309 17.4079L109.843 5.43439V17.6252H106.704V1.65645C106.704 1.33462 106.795 1.04291 106.976 0.781446C107.157 0.519987 107.388 0.324806 107.67 0.196047C107.968 0.0753078 108.273 0.0451946 108.587 0.105564C108.901 0.165932 109.171 0.312704 109.396 0.546021L120.862 12.5075V0.316786H124.001V16.2854Z"
          fill="#001D22"
        />
        <path
          d="M130.458 10.2383H141.49V3.45496H135.974C135.878 3.45496 135.674 3.46904 135.365 3.49718C135.055 3.52532 134.697 3.59569 134.29 3.7084C133.884 3.82098 133.455 3.99407 133.005 4.22738C132.554 4.4607 132.14 4.78267 131.762 5.19301C131.383 5.60335 131.072 6.11642 130.826 6.73194C130.581 7.34745 130.458 8.09384 130.458 8.97095V10.2383ZM144.628 17.6252H141.49V13.3765H130.458V17.6252H127.32V8.97095C127.32 7.70756 127.537 6.54492 127.972 5.48276C128.406 4.42059 129.01 3.50717 129.782 2.74277C130.555 1.97838 131.468 1.38284 132.522 0.956459C133.576 0.529932 134.727 0.31674 135.974 0.31674H143.047C143.264 0.31674 143.47 0.356985 143.663 0.437479C143.856 0.51783 144.025 0.630548 144.17 0.775351C144.314 0.920153 144.427 1.08916 144.508 1.28237C144.588 1.47544 144.628 1.68061 144.628 1.89788V17.6252Z"
          fill="#001D22"
        />
        <path
          d="M36.705 39.8723V26.7099H45.3446V27.8586H38.0026V32.6976H44.195V33.7728H38.0026V39.8723H36.705Z"
          fill="#001D22"
        />
        <path
          d="M47.6244 26.7099H48.922V39.8722H47.6244V26.7099Z"
          fill="#001D22"
        />
        <path
          d="M61.5658 27.8585H56.8756V39.8722H55.5776V27.8585H50.8873V26.7098H61.5658V27.8585Z"
          fill="#001D22"
        />
        <path
          d="M64.8282 29.1007V39.8723H63.5306V26.7099H64.6059L73.2456 37.6846V26.728H74.5426V39.8723H73.3381L64.8282 29.1007Z"
          fill="#001D22"
        />
        <path
          d="M86.6861 38.7226V39.8723H77.8427V26.7099H86.519V27.8585H79.1403V32.6052H85.5737V33.6984H79.1403V38.7226H86.6861Z"
          fill="#001D22"
        />
        <path
          d="M96.7535 29.1752C96.5684 28.9649 96.3522 28.7768 96.1048 28.6099C95.8572 28.4429 95.5786 28.2981 95.2699 28.1744C94.9611 28.0507 94.6311 27.9511 94.2781 27.8776C93.9261 27.8033 93.5519 27.766 93.1567 27.766C92.007 27.766 91.164 27.9852 90.6259 28.4239C90.0888 28.8614 89.8193 29.4568 89.8193 30.2092C89.8193 30.7282 89.946 31.1356 90.1994 31.4312C90.453 31.728 90.8512 31.9684 91.3954 32.1534C91.9396 32.3386 92.6316 32.5297 93.4724 32.7268C94.4109 32.925 95.2206 33.1593 95.9006 33.4309C96.5805 33.7025 97.1056 34.0696 97.4767 34.5323C97.8469 34.995 98.0329 35.6216 98.0329 36.4112C98.0329 37.0157 97.9153 37.5408 97.6808 37.9843C97.4456 38.4289 97.1177 38.799 96.6982 39.0957C96.2777 39.3914 95.7769 39.6108 95.1964 39.7525C94.6151 39.8943 93.9783 39.9648 93.2864 39.9648C92.6064 39.9648 91.9546 39.8943 91.3311 39.7515C90.7064 39.6098 90.116 39.3996 89.5597 39.1219C89.0036 38.8433 88.4846 38.4883 88.0027 38.0557L88.6515 36.9986C88.8859 37.246 89.1675 37.4783 89.4954 37.6936C89.8223 37.9109 90.1874 38.1051 90.5888 38.278C90.9911 38.451 91.4235 38.5838 91.8872 38.6763C92.3499 38.7698 92.8288 38.8161 93.3236 38.8161C94.3738 38.8161 95.1935 38.625 95.7798 38.2419C96.3672 37.8595 96.6609 37.2923 96.6609 36.54C96.6609 35.9968 96.5122 35.5622 96.2154 35.2353C95.9197 34.9084 95.4741 34.64 94.8806 34.4297C94.2882 34.2205 93.558 34.0163 92.693 33.8181C91.7786 33.6089 91.0062 33.3746 90.3754 33.1151C89.7459 32.8566 89.273 32.5135 88.9573 32.0881C88.6425 31.6616 88.4846 31.0973 88.4846 30.3933C88.4846 29.5785 88.6857 28.8905 89.087 28.3293C89.4893 27.768 90.0425 27.3426 90.7467 27.0518C91.4507 26.7622 92.2604 26.6163 93.1758 26.6163C93.7561 26.6163 94.2972 26.6757 94.7972 26.7924C95.298 26.91 95.7617 27.08 96.1882 27.3023C96.6147 27.5257 97.013 27.8033 97.3842 28.1372L96.7535 29.1752Z"
          fill="#001D22"
        />
        <path
          d="M108.007 29.1752C107.822 28.9649 107.605 28.7768 107.358 28.6099C107.111 28.4429 106.832 28.2981 106.523 28.1744C106.214 28.0507 105.884 27.9511 105.531 27.8776C105.179 27.8033 104.805 27.766 104.41 27.766C103.26 27.766 102.417 27.9852 101.879 28.4239C101.342 28.8614 101.073 29.4568 101.073 30.2092C101.073 30.7282 101.199 31.1356 101.453 31.4312C101.706 31.728 102.105 31.9684 102.649 32.1534C103.193 32.3386 103.885 32.5297 104.726 32.7268C105.664 32.925 106.474 33.1593 107.154 33.4309C107.834 33.7025 108.359 34.0696 108.73 34.5323C109.1 34.995 109.286 35.6216 109.286 36.4112C109.286 37.0157 109.169 37.5408 108.934 37.9843C108.699 38.4289 108.371 38.799 107.951 39.0957C107.531 39.3914 107.03 39.6108 106.45 39.7525C105.868 39.8943 105.232 39.9648 104.54 39.9648C103.86 39.9648 103.208 39.8943 102.584 39.7515C101.96 39.6098 101.369 39.3996 100.813 39.1219C100.257 38.8433 99.7379 38.4883 99.256 38.0557L99.9048 36.9986C100.139 37.246 100.421 37.4783 100.749 37.6936C101.076 37.9109 101.441 38.1051 101.842 38.278C102.244 38.451 102.677 38.5838 103.141 38.6763C103.603 38.7698 104.082 38.8161 104.577 38.8161C105.627 38.8161 106.447 38.625 107.033 38.2419C107.621 37.8595 107.914 37.2923 107.914 36.54C107.914 35.9968 107.765 35.5622 107.469 35.2353C107.173 34.9084 106.727 34.64 106.134 34.4297C105.541 34.2205 104.811 34.0163 103.946 33.8181C103.032 33.6089 102.259 33.3746 101.629 33.1151C100.999 32.8566 100.526 32.5135 100.211 32.0881C99.8958 31.6616 99.7379 31.0973 99.7379 30.3933C99.7379 29.5785 99.939 28.8905 100.34 28.3293C100.743 27.768 101.296 27.3426 102 27.0518C102.704 26.7622 103.514 26.6163 104.429 26.6163C105.009 26.6163 105.551 26.6757 106.05 26.7924C106.551 26.91 107.015 27.08 107.442 27.3023C107.868 27.5257 108.266 27.8033 108.638 28.1372L108.007 29.1752Z"
          fill="#001D22"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_2101">
          <rect width="144.697" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
