const wrapper = {
  background: "transparent",
  padding: "3rem 2rem",
  display: "flex",
  flexDirection: "column",

  height: "100vh",
};

const logoBox = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "3rem",
};

const form = {
  display: "flex",
  flexDirection: "column",
};

const inputField = {
  marginTop: "1rem",
};

const textareaFiled = {
  borderRadius: "12px 12px 0 12px",
  padding: "16.5px 14px",
};

const submitButton = {
  fontSize: "1rem",
  padding: "0.8rem 0",
  color: "white",
  borderRadius: "8px",
  marginTop: "2rem",
  width: "100%",
};

const createAccountBox = {
  marginTop: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "20px 0",
};

const makeRequest = {
  fontWeight: "bold",
  marginLeft: "0.3rem",
  cursor: "pointer",
};

export {
  wrapper,
  logoBox,
  form,
  textareaFiled,
  submitButton,
  inputField,
  createAccountBox,
  makeRequest,
};
