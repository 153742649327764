import {
  AZURE_WEB_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  DIM_GREY_COLOR,
} from "../../../../../styles";

const infoBoxStyle = {
  background: "#ffffff",
  width: "100%",
  maxWidth: "400px",
  padding: "1rem",
  borderRadius: "8px",
};

const heading = {
  fontSize: "2.2rem",
  fontWeight: "bold",
  color: PRIMARY_COLOR,
};

const divider = {
  background: SECONDARY_COLOR,
  height: "2px",
  borderRadius: "8px",
  marginBottom: "0.5rem",
};

const infoText = {
  color: PRIMARY_COLOR,
  display: "flex",
};

const valueText = {
  color: DIM_GREY_COLOR,
  fontWeight: "bold",
  marginLeft: "0.3rem",
};

export { infoBoxStyle, heading, divider, valueText, infoText };
