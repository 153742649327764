import { Box, Typography, TextField } from "@mui/material";
//Style
import { title, textBox } from "../../style";
//Config
import { ageQuestion } from "../../../../config";

type Props = {
  onChange: (key: string, value: string) => void;
  value?: string;
};

export const AddAge = ({ onChange, value }: Props) => {
  const key = "age";

  const onChangeHandler = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <Box>
      <Typography variant="h2" sx={title}>
        {ageQuestion}
      </Typography>

      <Box sx={textBox}>
        <TextField
          value={value}
          onChange={(e) => onChangeHandler(key, e.target.value)}
        />
      </Box>
    </Box>
  );
};
