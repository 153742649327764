import { Button, Typography } from "@mui/material";
//Assets
import { DescriptionIcon } from "../../assets";
//Style
import { addBtn, text } from "./style";

type Props = {
  onClick: () => void;
};

export const AddDescriptionToTraining = ({ onClick }: Props) => {
  const onClickHandler = () => {
    onClick();
  };
  return (
    <Button onClick={onClickHandler} sx={addBtn}>
      <DescriptionIcon />
      <Typography sx={text}>Dodaj opis</Typography>
    </Button>
  );
};
