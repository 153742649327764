import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
//Assets
import { ContextIcon, EditIcon } from "../../assets";
//Style
import { menuItemTitle } from "./style";
//Config
import { EDIT_PATH } from "../../config";

type Props = {
  userId: string;
};

export const MenuPopover = ({ userId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onCloseHandler = () => {
    setAnchorEl(null);
  };

  const onEditHandler = (event: any) => {
    event.stopPropagation();
    navigate(`${EDIT_PATH}/${userId}`);
  };

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onClickHandler}
      >
        <ContextIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseHandler}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onEditHandler}>
          <EditIcon />
          <Typography sx={menuItemTitle}>Izmeni</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Deaktiviraj</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Obriši</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
