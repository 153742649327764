import {
  MIDNIGHT_GREEN_COLOR,
  PRIMARY_COLOR,
  TERNARY_COLOR,
} from "../../../../styles";
const tableRow = {
  background: TERNARY_COLOR,
};

const tableCell = {
  color: PRIMARY_COLOR,
  background: TERNARY_COLOR,
};

export { tableRow, tableCell };
