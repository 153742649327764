import { Theme, Box } from "@mui/material";
import { styled } from "@mui/system";

type StylesTypes = {
  isActive: boolean;
  theme: Theme;
};

const surveyPage = {
  width: "1300px",
  padding: "2rem 0.5rem",
  maxWidth: "90%",
  margin: "0 auto",
};

const mainContentSection = {
  width: "500px",
  maxWidth: "100%",
  margin: "5rem auto 5rem auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const btnBox = {
  position: "absolute",
  bottom: "50px",
  left: " 50%",
  transform: "translate(-50%, -50%)",

  display: "flex",
  justifyContent: "center",
  width: "500px",
  maxWidth: "85%",
  margin: "2rem auto",
};

const startBtn = {
  width: "100%",
};

const buttonsBox = {
  position: "absolute",
  bottom: "50px",
  left: " 50%",
  transform: "translate(-50%, -50%)",

  display: "flex",
  justifyContent: "space-between",
  width: "350px",
  maxWidth: "85%",
  margin: "2rem auto",
};

const nextPageBtn = {
  width: "230px",
};

//Style for all sections
const title = {
  fontSize: "2rem",
  fontWeight: "600",
  textAlign: "center",
};

const textBox = {
  marginTop: "6rem",
  display: "flex",
  maxWidth: "450px",
  alignItems: "center",
  flexDirection: "column",
};

const sectionBoxWrapper = {
  maxWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const StyledOptionLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive, theme }: StylesTypes) => ({
  padding: "0.8rem 1.8rem",
  border: "1px solid grey",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "200px",
  margin: "0 0.8rem",
  cursor: "pointer",
  background: isActive ? theme.palette.grey[300] : theme.palette.common.white,
}));

export {
  surveyPage,
  mainContentSection,
  btnBox,
  startBtn,
  buttonsBox,
  nextPageBtn,
  title,
  textBox,
  StyledOptionLabel,
  sectionBoxWrapper,
};
