import axios from "axios";
import { TOKEN } from "../config";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const accessToken = localStorage.getItem(TOKEN);
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

export default apiInstance;
