import { Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  PRIMARY_COLOR,
  MIDNIGHT_GREEN_COLOR,
  TERNARY_COLOR,
} from "../../../../../styles";

type StylesTypes = {
  isActive: boolean;
};

const selectorBar = {
  maxWidth: "450px",
  borderRadius: "8px",
  padding: "1rem",
  display: "flex",
  justifyContent: "space-evenly",
};

const StyledBarItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }: StylesTypes) => ({
  cursor: "pointer",
  background: isActive ? MIDNIGHT_GREEN_COLOR : "transparent",
  color: isActive ? TERNARY_COLOR : PRIMARY_COLOR,
  borderRadius: "8px",
  padding: "0.6rem 1rem",
  border: `1px solid ${MIDNIGHT_GREEN_COLOR}`,
  marginRight: "0.5rem",
}));

export { selectorBar, StyledBarItem };
