import apiInstance from "./api";
import { TRAINING, TRAININGS_SERVER_PATH } from "../config";
//Types
// import { TrainingType } from "../types";
//Ovde u exercises saljes array stringova, a kada prikazujes, dobijas u drugacijem formatu, sredi to

const create = async (training: any) => {
  try {
    const response = await apiInstance.post(
      `/${TRAININGS_SERVER_PATH}/create`,
      training
    );
    return response;
  } catch (err) {
    return {};
  }
};

const getById = async (id: string) => {
  try {
    const response = await apiInstance.get(`/${TRAININGS_SERVER_PATH}/${id}`);
    return response.data;
  } catch (err) {
    return {};
  }
};

const deleteById = async (id: string) => {
  try {
    const response = await apiInstance.delete(
      `/${TRAININGS_SERVER_PATH}/${id}`
    );
    return response.data;
  } catch (err) {
    return {};
  }
};

export const TrainingServices = {
  create,
  getById,
  deleteById,
};
