const textBox = {
  marginTop: "6rem",
  display: "flex",
};

//styledOptionLabel
const optionLabel = {
  padding: "1rem 2rem",
  border: "1px solid grey",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "200px",
  margin: "0 0.8rem",
  cursor: "pointer",
};

const optionText = {
  fontSize: "1.3rem",
};

export { textBox, optionLabel, optionText };
