import { Typography } from "@mui/material";
import { styled } from "@mui/system";

type StylesTypes = {
  isDark: boolean;
};

const StyledContactHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark }: StylesTypes) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  textTransform: "uppercase",
  color: isDark ? "black" : "white",
}));

const emailBox = {
  display: "flex",
  alignItems: "center",
};

const StyledEmailText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark }: StylesTypes) => ({
  marginLeft: "0.8rem",
  color: isDark ? "black" : "#ffffffa6",
  fontWeight: "bold",
  cursor: "pointer",
  "&:hover": {
    color: isDark ? "black" : "#00ffb7",
  },
}));

export { StyledContactHeading, emailBox, StyledEmailText };
