import { Box, Typography, useTheme } from "@mui/material";
//Style
import { textBox, optionText } from "./style";
import { title, sectionBoxWrapper } from "../../style";
import { StyledOptionLabel } from "./style";
//Config
import { bloodPresureQuestion } from "../../../../config";

type Props = {
  onClick: (key: string, value: string) => void;
  value: string;
};

export const BloodPresure = ({ onClick, value }: Props) => {
  const key = "bloodPresure";

  const onClickHandler = (key: string, value: string) => {
    onClick(key, value);
  };

  const theme = useTheme();

  return (
    <Box sx={sectionBoxWrapper}>
      <Typography variant="h1" sx={title}>
        {bloodPresureQuestion}
      </Typography>
      <Box sx={textBox}>
        <StyledOptionLabel
          isActive={value === "Visok"}
          theme={theme}
          onClick={() => onClickHandler(key, "Visok")}
        >
          <Typography sx={optionText}>Visok</Typography>
        </StyledOptionLabel>
        <StyledOptionLabel
          theme={theme}
          isActive={value === "Nizak"}
          onClick={() => onClickHandler(key, "Nizak")}
        >
          <Typography sx={optionText}>Nizak</Typography>
        </StyledOptionLabel>
        <StyledOptionLabel
          theme={theme}
          isActive={value === "Ne znam"}
          onClick={() => onClickHandler(key, "Ne znam")}
        >
          <Typography sx={optionText}>Ne znam</Typography>
        </StyledOptionLabel>
      </Box>
    </Box>
  );
};
