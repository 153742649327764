import apiInstance from "./api";
import { EXERCISES_SERVER_PATH } from "../config";
import { ExerciseType } from "../types";

const getAll = async () => {
  try {
    const response = await apiInstance.get(`/${EXERCISES_SERVER_PATH}`);
    return response.data;
  } catch (err) {
    return [];
  }
};

const create = async (exercise: ExerciseType) => {
  try {
    const response = await apiInstance.post(
      `/${EXERCISES_SERVER_PATH}/create`,
      exercise
    );
    return response;
  } catch (err) {
    return {};
  }
};

export const ExerciseServices = {
  getAll,
  create,
};
