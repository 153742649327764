import apiInstance from "./api";
//Types
import { UserType } from "../types";
//Constants
import { USERS } from "../config";

const getAll = async () => {
  try {
    const response = await apiInstance.get(`/${USERS}`);
    return response.data;
  } catch (err) {
    return [];
  }
};

const getById = async (id: string) => {
  try {
    const response = await apiInstance.get(`/${USERS}/${id}`);
    return response.data;
  } catch (err) {
    return {};
  }
};

const getTrainingsByUserId = async (id: string) => {
  try {
    const response = await apiInstance.get(`/${USERS}/${id}`);
    return response.data;
  } catch (err) {}
};

const create = async (user: any) => {
  try {
    const response = await apiInstance.post(`/${USERS}/create`, user);
    return response;
  } catch (err) {
    return {};
  }
};

const edit = async (editedUser: UserType | any) => {
  try {
    const response = await apiInstance.put(`/${USERS}/update`, editedUser);
    return response;
  } catch (err) {
    return {};
  }
};

export const UserServices = {
  getAll,
  getById,
  create,
  edit,
  getTrainingsByUserId,
};
