const primary = {
  main: "#001D22",
};

const secondary = {
  main: "#67cccc",
  light: "#c1e8e4",
};

const grey = {
  100: "#F5F5F5",
  200: "#DDE1E5",
  300: "#B3B3B3",
  400: "#6B6B78",
  500: "#3C3C3C",
  600: "#333333",
  700: "#2F2F2F",
  800: "#252525",
  900: "#1C1C1C",
};

const common = {
  white: "#ffffff",
};

export { primary, secondary, grey, common };
