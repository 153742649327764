import { SECONDARY_COLOR } from "../../styles";

const userInfoBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2.5rem",
  border: `1px solid ${SECONDARY_COLOR}`,
  borderRadius: "8px",
  padding: " 0.5rem 1.2rem",
};

const iconBox = {
  marginRight: "1rem",
};

const nameTitle = {
  color: "white",
  fontWeight: "500",
  fontSize: "0.9rem",
};

const emailTitle = {
  color: "white",
  fontSize: "0.7rem",
};

export { userInfoBox, iconBox, nameTitle, emailTitle };
