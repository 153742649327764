const genderQuestion = "Kog ste pola?";
const ageQuestion = "Koliko imate godina?";
const addMeasuresQuestion = "Unesite vašu visinu i težinu";
const experienceInTrainingQuestion =
  "Da li imate iskustva u teretani / treningu snage u kućnim uslovima i koliko dugo (kratak pisani odgovor) ?";
const otherActivitiesQuestion =
  "Da li praktikujete neki drugi vid fizičke aktivnosti i koliko dugo (trčanje, šetanje, planinarenje)?";
const helathIssuesQuestion =
  "Da li imate zdravstveni problem zbog kojeg Vam je lekar preporučio da ne trebate da se bavite fizičkom aktivnošću?";
const healthHearthIssueQuestion = "Da li imate probleme sa srcem?";
const bloodPresureQuestion = "Da li znate vrednosti vašeg krvnog pritiska?";
const painQuestion = "Da li imate bilo kakve povrede ili bolove?";
const goalQuestion = "Koji je vaš trenažni cilj?";
const periodOfTimeForGoalQuestion =
  "U kom vremenskom periodu bi želeli da ostvarite vaš cilj?";
const motivationQuestion = "Koliko ste motivisani da promenite životni stil?";

const fullNameQuestion = "Ime i prezime";
const emailQuestion = "Email";
const heightOverviewAnswer = "Visina";
const weightOverviewAnswer = "Теžina";

export {
  genderQuestion,
  ageQuestion,
  addMeasuresQuestion,
  experienceInTrainingQuestion,
  otherActivitiesQuestion,
  helathIssuesQuestion,
  healthHearthIssueQuestion,
  bloodPresureQuestion,
  painQuestion,
  goalQuestion,
  periodOfTimeForGoalQuestion,
  motivationQuestion,
  fullNameQuestion,
  emailQuestion,
  heightOverviewAnswer,
  weightOverviewAnswer,
};
