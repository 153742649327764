import { Box, TextField, Typography } from "@mui/material";
//Style
import { textBox, inputBox } from "./style";
import { title } from "../../style";
//Config
import { addMeasuresQuestion } from "../../../../config";

type Props = {
  onChange: (key: string, value: string) => void;
  height?: string;
  weight?: string;
};

export const AddMeasures = ({ onChange, height, weight }: Props) => {
  const keyHeight = "height";
  const keyWeight = "weight";

  const onChangeHandler = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <Box>
      <Box>
        <Typography variant="h2" sx={title}>
          {addMeasuresQuestion}
        </Typography>
        <Box sx={textBox}>
          <Box sx={inputBox}>
            <TextField
              label="Unesite visinu (cm)"
              value={height}
              onChange={(e) => onChangeHandler(keyHeight, e.target.value)}
            />
          </Box>
          <Box sx={inputBox}>
            <TextField
              label="Unesite težinu (kg)"
              value={weight}
              onChange={(e) => onChangeHandler(keyWeight, e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
