type Props = {
  width?: string;
  height?: string;
};

export const LogoutIcon = ({ width = "48", height = "48" }: Props) => {
  return (
    <svg
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
    >
      <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h299v60H180v600h299v60H180Zm486-185-43-43 102-102H360v-60h363L621-612l43-43 176 176-174 174Z" />
    </svg>
  );
};
