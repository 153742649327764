import { useState } from "react";
import { Box, Typography, Dialog, Button } from "@mui/material";
//Assets
import { LogoutIcon } from "../../assets";
//Style
import {
  logoutBox,
  logoutTitle,
  popupContentWrapper,
  btnBox,
  btnYes,
  btnNo,
} from "./style";

export const LogoutButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickHandler = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <Dialog open={isOpen} onClose={onClickHandler}>
        <Box sx={popupContentWrapper}>
          <Typography>Da li želite da se odjavite?</Typography>
          <Box sx={btnBox}>
            <Button variant="contained" sx={btnYes}>
              Da
            </Button>
            <Button sx={btnNo}>Ne</Button>
          </Box>
        </Box>
      </Dialog>
      <Box sx={logoutBox} onClick={onClickHandler}>
        <LogoutIcon width="35" height="35" />
        <Typography sx={logoutTitle}>Odjavi se</Typography>
      </Box>
    </>
  );
};
