import { Box, Typography } from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { DayCard, BackButton } from "../../../components";
//Style
import { page, pageHeader, heading, list } from "./style";
//Config
import { data } from "../userHomePage/config/data";

type Props = {
  navItems: { title: string; path: string }[];
};

const { month, trainings, numberOfTrainings } = {
  month: "Januar",
  numberOfTrainings: 5,
  trainings: data,
};

export const UserHistoryListForSpecificMonthPage = ({ navItems }: Props) => {
  return (
    <Layout userNavItems={navItems}>
      <Box sx={page}>
        <Box sx={pageHeader}>
          <Box>
            <Typography sx={heading}>{month}</Typography>
            <Typography>Broj treninga: {numberOfTrainings}</Typography>
          </Box>

          <BackButton />
        </Box>

        <Box sx={list}>
          {/* {trainings.map(({ day, date, content }) => (
            <DayCard day={day} date={date} content={content} />
          ))} */}
        </Box>
      </Box>
    </Layout>
  );
};
