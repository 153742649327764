import React from "react";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
//Style
import { navItem } from "./style";
import { SECONDARY_COLOR } from "../../styles";

type Props = {
  title: string;
  path: string;
  icon?: any;
};

export const NavItem = ({ title, path, icon: Icon }: Props) => {
  const iconElement = React.createElement(Icon, { fill: SECONDARY_COLOR });
  return (
    <NavLink
      key={title}
      to={path}
      style={navItem}
      className={({ isActive }) => (isActive ? "active" : "")}
    >
      <Box sx={{ marginRight: "1.5rem", marginLeft: "0.5rem" }}>
        {Icon ? iconElement : null}
      </Box>
      <Box>
        {title}
        {title === "Podrška" ? <Box>(2)</Box> : null}
      </Box>
    </NavLink>
  );
};
