import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
//Components
import { InfoBox } from "./components/infoBox";
import { AddTrainingBox } from "./components/addTrainingBox";
import { Layout } from "../../../layout";
import { BackButton, DayCard } from "../../../components";
//Style
import { pageStyle, pageHeader, createdTraingsList } from "./style";
//Hooks
import { useGetUserById } from "../../../hooks";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerDetailPage = ({ navItems }: Props) => {
  const { id } = useParams();

  const { user } = useGetUserById(id!);
  const trainings = user?.trainings;

  return (
    <>
      <Layout adminNavItems={navItems}>
        <Box sx={pageStyle}>
          <Box sx={pageHeader}>
            <InfoBox
              fullName={user?.fullName}
              selectedPackage={user?.packageType}
              startDate={user?.startDate}
              endDate={user?.endDate}
            />

            <BackButton />
          </Box>
          <Box sx={createdTraingsList}>
            {trainings?.map(({ id, date, trainingSummary }) => {
              return (
                <DayCard
                  key={id}
                  trainingId={id}
                  date={date}
                  trainingSummary={trainingSummary}
                  isAdmin={true}
                  userId={user?.id}
                  isDraftTrainingCard={true}
                />
              );
            })}
          </Box>
          <AddTrainingBox userId={user?.id} />
        </Box>
      </Layout>
    </>
  );
};
