export const InfoIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998ZM8.00004 4.16665C8.27618 4.16665 8.50004 4.3905 8.50004 4.66665V8.66665C8.50004 8.94279 8.27618 9.16665 8.00004 9.16665C7.7239 9.16665 7.50004 8.94279 7.50004 8.66665V4.66665C7.50004 4.3905 7.7239 4.16665 8.00004 4.16665ZM8.00004 11.3333C8.36823 11.3333 8.66671 11.0348 8.66671 10.6666C8.66671 10.2985 8.36823 9.99998 8.00004 9.99998C7.63185 9.99998 7.33337 10.2985 7.33337 10.6666C7.33337 11.0348 7.63185 11.3333 8.00004 11.3333Z"
        fill="#67CCCC"
      />
    </svg>
  );
};
