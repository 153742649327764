import { MIDNIGHT_GREEN_COLOR, AZURE_WEB_COLOR } from "../../styles";

const dayCard = {
  padding: "16px",
  margin: "1rem",
  background: "white",
  border: `1px solid ${AZURE_WEB_COLOR}`,
  borderRadius: "12px",
  cursor: "pointer",
  width: "340px",
  maxWidth: "95%",
};

const cardHeader = {
  display: "flex",
  justifyContent: "space-between",
};

const dayTitle = {
  color: MIDNIGHT_GREEN_COLOR,
  fontWeight: "bold",
  fontSize: "20px",
};

const dateTitle = {
  textAlign: "center",
  fontSize: "0.8rem",
};

const contentList = {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
};

const btnBox = {
  display: "flex",
  justifyContent: "flex-end",
};

export { dayCard, cardHeader, dayTitle, dateTitle, contentList, btnBox };
