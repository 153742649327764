const showcaseImage = require("../../assets/images/showcase.jpg");
const showcaseFAQ = {
  position: "relative",
  width: "100%",
  height: "500px",
  background: `url(${showcaseImage}) center center`,
  backgroundSize: "cover",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "::after": {
    content: '""',
    zIndex: "-1",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(16, 16, 17, 0.85)",
  },
};

const showcaseContent = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const showcaseDescription = {
  minWidth: "60%",
  width: "600px",
  color: "white",
};

const heading = {
  color: "white",
  fontSize: "4.3rem",
  fontWeight: "bold",
};

const mainContentWrapper = {
  width: "90%",
  maxWidth: "1100px",
  display: "flex",
  justifyContent: "center",
  margin: "2rem auto",
};

export {
  showcaseFAQ,
  heading,
  showcaseContent,
  showcaseDescription,
  mainContentWrapper,
};
