import { Box } from "@mui/material";
//Components
import { TrainingAccordionItem } from "../../../../components";
//Types
import { ExerciseType } from "../../../../types";

type Props = {
  data: any;
  isExercisePage?: boolean;
  onAddExerciseToUserHandler?: (exercise: any) => void;
  onRemoveExerciseToUserHandler?: (exerciseId: string) => void;
};

export const ListOfTrainingItems = ({
  data,
  isExercisePage,
  onAddExerciseToUserHandler,
  onRemoveExerciseToUserHandler,
}: Props) => {
  return (
    <Box sx={{ marginTop: "2rem" }}>
      {data?.map(
        ({ id, defaultDescription, description, videoURL, category }: any) => (
          <TrainingAccordionItem
            key={id}
            id={id}
            category={category}
            isExercisePage={isExercisePage}
            predefinedNotes={defaultDescription}
            title={description}
            videoURL={videoURL}
            onAddExerciseToUserHandler={onAddExerciseToUserHandler}
            onRemoveExerciseToUserHandler={onRemoveExerciseToUserHandler}
          />
        )
      )}
    </Box>
  );
};
