const popupContent = {
  padding: "2rem 1rem",
  width: "450px",
  maxWidth: "90%",
  margin: "0 auto",
};

const iconBox = {
  marginBottom: "1rem",
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer",
};

const textFieldBox = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const textField = {
  width: "400px",
};

const title = {
  fontSize: "1.4rem",
  marginBottom: "1.5rem",
};

const addBtn = {
  background: "#212B36",
  color: "white",
  borderRadius: "8px",
  display: "inline-block",
  padding: "0.4rem 1.5rem",
  marginLeft: "0.5rem",
};

const closeBtn = {
  color: "#212B36",
  borderColor: "#212B36",
};

const btnContainer = {
  marginTop: "1rem",
  display: "flex",
  justifyContent: "flex-end",
};

export {
  popupContent,
  iconBox,
  textFieldBox,
  textField,
  title,
  addBtn,
  closeBtn,
  btnContainer,
};
