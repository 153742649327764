import { Box } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
//Assets
import { IconUpload } from "../../assets";
//Style
import { dropzoneBox } from "./style";

export const Dropzone = () => {
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()} sx={dropzoneBox}>
      <input {...getInputProps()} />
      <Box>
        <IconUpload />
      </Box>
    </Box>
  );
};
