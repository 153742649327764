import { PRIMARY_COLOR } from "../../../../../styles";
const addBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "300px",
  height: "350px",
  maxWidth: "90%",
  border: `2px solid ${PRIMARY_COLOR}`,
  borderRadius: "8px",
  cursor: "pointer",
  marginTop: "2rem",
  marginLeft: "1rem",
};

export { addBox };
