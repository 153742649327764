import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
//Utils
import { getDayBasedOnTimestamp, getDateBasedOnTimestamp } from "../../utils";
//Services
import { TrainingServices } from "../../services";
//Style
import {
  dayCard,
  cardHeader,
  dayTitle,
  dateTitle,
  contentList,
  btnBox,
} from "./style";
//Config
import {
  TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH,
  CUSTOMER,
  TRAINING,
} from "../../config";
//Assets
import { CloseIcon } from "../../assets";

type Props = {
  trainingId?: string;
  date: string;
  trainingSummary: string;
  isAdmin?: boolean;
  isDraftTrainingCard?: boolean;
  userId?: string;
};

export const DayCard = ({
  trainingId,
  date,
  trainingSummary,
  isAdmin,
  isDraftTrainingCard,
  userId,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const primaryMainColor = theme.palette.primary.main;
  const secondaryLightColor = theme.palette.secondary.light;

  const day = getDayBasedOnTimestamp(date);
  const trainingSummaryList = trainingSummary.split(";");

  const onClickHandler = (e: any) => {
    e.stopPropagation();
    const adminPathToNavigate = `${CUSTOMER}/${userId}/${TRAINING}/${trainingId}`;
    const userPathToNavigate = `${TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH}/${day.toLowerCase()}/${trainingId}`;
    navigate(isAdmin ? adminPathToNavigate : userPathToNavigate);
  };

  const onDeleteTrainingHandler = (e: any) => {
    e.stopPropagation();
    try {
      TrainingServices.deleteById(trainingId!);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Box sx={dayCard} onClick={onClickHandler}>
      <Box sx={cardHeader}>
        <Box>
          <Typography sx={dayTitle} variant="h4">
            {day}
          </Typography>
          <Typography sx={dateTitle} variant="body2">
            {getDateBasedOnTimestamp(date)}
          </Typography>
        </Box>

        {isDraftTrainingCard ? (
          <Box sx={btnBox} onClick={onDeleteTrainingHandler}>
            <CloseIcon width="22" height="22" />
          </Box>
        ) : null}
      </Box>

      <Typography component="ul" sx={contentList}>
        {trainingSummaryList.map((item) => {
          return (
            <Typography
              sx={{
                color: primaryMainColor,
                background: secondaryLightColor,
                padding: "0.1rem 0.5rem",
                borderRadius: "8px",
                fontSize: "0.9rem",
                marginRight: "0.3rem",
              }}
              key={item}
              component="li"
            >
              {item}
            </Typography>
          );
        })}
      </Typography>
    </Box>
  );
};
