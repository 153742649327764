import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../styles";

const userHistoryOverivewPage = {
  background: "rgb(249,249,249)",
  width: "100%",
  minHeight: "100vh",
  padding: "2rem",
};

const pageHeader = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2.5rem",
};

const heading = {
  fontSize: "2.5rem",
  fontWeight: "bold",
};

const itemList = {
  marginTop: "3rem",
};
const monthItemBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  maxWidth: "1000px",
  background: "white",
  padding: "2rem",
  margin: "1rem auto",
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid white",

  "&:hover": {
    border: `1px solid ${SECONDARY_COLOR}`,
  },
};

const monthTitle = {
  color: PRIMARY_COLOR,
  fontSize: "1.4rem",
  fontWeight: "600",
};

export {
  userHistoryOverivewPage,
  pageHeader,
  heading,
  itemList,
  monthItemBox,
  monthTitle,
};
