import { PRIMARY_COLOR } from "../../../styles";

const page = {
  width: "100%",
  padding: "3rem 2rem",
};

const heading = {
  fontSize: "2.2rem",
  fontWeight: "bold",
  color: PRIMARY_COLOR,
};

const headingBox = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2rem",
};

const searchBarBox = {
  margin: "3rem 0",
};

const addNewExcerciseBtn = {
  background: PRIMARY_COLOR,
  borderRadius: "8px",
  textTransform: "Capitalize",
  "&:hover": {
    background: PRIMARY_COLOR,
    opacity: "0.9",
  },
};

export { page, heading, headingBox, searchBarBox, addNewExcerciseBtn };
