import { Typography, Box } from "@mui/material";
import { Layout } from "../../../layout";
import { useNavigate } from "react-router-dom";
//Components
import { BackButton } from "../../../components";
//Config
import { USER_HISTORY_OVERVIEW_PAGE } from "../../../config";
//Style
import {
  userHistoryOverivewPage,
  pageHeader,
  monthItemBox,
  monthTitle,
  heading,
  itemList,
} from "./style";

type Props = {
  navItems: { title: string; path: string }[];
};

const months = [
  { month: "Januar", numberOfTrainings: 12 },
  { month: "Februar", numberOfTrainings: 12 },
  { month: "Mart", numberOfTrainings: 12 },
];

export const UserHistoryOverviewPage = ({ navItems }: Props) => {
  const navigate = useNavigate();

  const onClickHandler = (month: string) => {
    navigate(`${USER_HISTORY_OVERVIEW_PAGE}/${month.toLowerCase()}`);
  };

  return (
    <Layout userNavItems={navItems}>
      <Box sx={userHistoryOverivewPage}>
        <Box sx={pageHeader}>
          <Typography sx={heading} variant="h1">
            Istorija
          </Typography>
          <BackButton />
        </Box>
        <Box sx={itemList} component="ul">
          {months.map(({ month, numberOfTrainings }) => {
            return (
              <Box
                sx={monthItemBox}
                key={month}
                onClick={() => onClickHandler(month)}
              >
                <Typography sx={monthTitle}>{month}</Typography>
                <Typography>Broj treninga: {numberOfTrainings}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Layout>
  );
};
