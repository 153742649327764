type Props = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AddIcon = ({
  width = "48",
  height = "48",
  fill = "#471aff",
}: Props) => {
  return (
    <svg
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
    >
      <path d="M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z" />
    </svg>
  );
};
