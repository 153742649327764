import { PRIMARY_COLOR } from "../../styles";

const addBtn = {
  border: `1px solid ${PRIMARY_COLOR}`,
  textTransform: "capitalize",
  marginBottom: "1rem",
  width: "300px",
  borderRadius: "8px",
};

const text = {
  marginLeft: "0.3rem",
  color: PRIMARY_COLOR,
};

export { addBtn, text };
