const USERS = "users";
const EXERCISES_SERVER_PATH = "exercises";
const TRAININGS_SERVER_PATH = "trainings";

const VEZBE_ZAGREVANJA = "Zagrevanje";
const TRENING = "Trening";
const VEZBE_ISTEZANJA = "Isstezanja";

const TOKEN = "token";
const FULLUSER = "fulluser";

export {
  USERS,
  EXERCISES_SERVER_PATH,
  VEZBE_ISTEZANJA,
  VEZBE_ZAGREVANJA,
  TRENING,
  TRAININGS_SERVER_PATH,
  TOKEN,
  FULLUSER,
};
