import { Box } from "@mui/material";
//Style
import { selectorBar, StyledBarItem } from "./style";

type Props = {
  data: string[];
  selectedTraining: string;
  onClick: (partOfTraining: string) => void;
};

export const SelectorTrainingBar = ({
  data,
  selectedTraining,
  onClick,
}: Props) => {
  const onClickHandler = (partOfTraining: string) => {
    onClick(partOfTraining);
  };
  return (
    <Box sx={selectorBar}>
      {data.map((item) => (
        <StyledBarItem
          key={item}
          isActive={selectedTraining === item}
          onClick={() => onClickHandler(item)}
        >
          {item}
        </StyledBarItem>
      ))}
    </Box>
  );
};
