const pageStyle = {
  width: "90%",
  maxWidth: "1000px",
  margin: "1rem auto",
  "@media(max-width:600px)": {
    width: "100%",
    margin: "0rem auto",
  },
};

const pageHeader = {
  display: "flex",
  justifyContent: "space-between",
};

export { pageStyle, pageHeader };
