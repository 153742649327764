const pageStyle = {
  padding: "3rem 2.5rem",
  width: "100%",
  backgrund: "rgba(0,0,0,0.2)",
};

const pageHeader = {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px dotted grey",
  marginBottom: "2rem",
};

const createdTraingsList = {
  display: "flex",
  flexWrap: "wrap",
};

export { pageStyle, pageHeader, createdTraingsList };
