import { Box, Typography } from "@mui/material";
//Style
import { item } from "./style";

type Props = {
  question: string;
  answer?: string;
};
export const QuestionAndAnswerItem = ({ question, answer }: Props) => {
  return (
    <Box sx={item}>
      <Typography>{question}:</Typography>
      <Typography>{answer || "-"}</Typography>
    </Box>
  );
};
