const labelStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "150px",
  border: "1px solid #161617",
  borderRadius: "8px",
  padding: "0.2rem 0.8rem 0.2rem 1rem",
  margin: "0.5rem",
};

const iconBox = {
  cursor: "pointer",
};

export { labelStyle, iconBox };
