import { AZURE_WEB_COLOR, PRIMARY_COLOR } from "../../styles";

const addDescriptionPreview = {
  background: AZURE_WEB_COLOR,
  padding: "1.5rem",
  margin: "1rem 0 1rem 0",
  width: "90%",
  maxWidth: "600px",
  borderRadius: "8px",
};

const textStyle = {
  color: PRIMARY_COLOR,
};

export { addDescriptionPreview, textStyle };
