import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { TableRowComponent } from "./tableRowComponent";
import { TableHeadComponent } from "./tableHeadComponent";
//Config
import { CUSROMER_TABLE_HEAD } from "../../../mockData/customerAccounts";
//hooks
import { useGetAllUsers } from "../../../hooks/useGetAllUsers";
//Types
import { UserType } from "../../../types";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerAccountsPage = ({ navItems }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { users } = useGetAllUsers();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Layout adminNavItems={navItems}>
      <Paper sx={{ width: "100%", overflow: "hidden", padding: "2rem" }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHeadComponent tableHeadItems={CUSROMER_TABLE_HEAD} />
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: UserType) => {
                  return (
                    <TableRowComponent
                      key={user.id}
                      id={user.id}
                      fullname={user.fullName}
                      selectedPackage={user.packageType}
                      startDate={user.startDate}
                      endDate={user.endDate}
                      sumOfMonths={user.sumOfPaidMonths}
                      pricePerMonth={user.priceForThisMonth}
                      sumPrice={user.sumPrice}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
};
