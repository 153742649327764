import { useEffect, useState } from "react";
import { useAuthenticationContext } from "../context";
import { UserServices } from "../services";
import { TrainingType } from "../types";

export const useGetTrainingsByUserId = () => {
  const { user } = useAuthenticationContext();
  const [trainings, setTrainings] = useState<TrainingType[]>([]);

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const trainings = await UserServices.getTrainingsByUserId(user.id);

        setTrainings(trainings?.trainings);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTrainings();
  }, [user]);

  return { trainings };
};
