import { Box, Typography } from "@mui/material";
//Assets
import { UserIcon } from "../../assets";
//Style
import { userInfoBox, iconBox, nameTitle, emailTitle } from "./style";

type Props = {
  email: string;
  fullName: string;
};

export const UserInfoBox = ({ email, fullName }: Props) => {
  return (
    <Box sx={userInfoBox}>
      <Box sx={iconBox}>
        <UserIcon width="30" height="30" />
      </Box>
      <Box>
        <Typography sx={nameTitle}>{fullName}</Typography>
        <Typography sx={emailTitle}>{email}</Typography>
      </Box>
    </Box>
  );
};
