export const NewBackIcon = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="38.5"
        rx="15.25"
        stroke="#012E33"
        strokeWidth="1.5"
      />
      <path
        d="M22.6667 20H9.33334M9.33334 20L14.3333 15M9.33334 20L14.3333 25"
        stroke="#012E33"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
