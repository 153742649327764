const trainingDetailSection = {
  margin: "2rem 0",
};

const partOfTrainingStyle = {
  fontSize: "1.8rem",
  fontWeight: "bold",
};

const titleStyle = {
  fontSize: "1.4rem",
  fontWeight: "500",
  color: "#471aff",
};

const openVideoBtn = {
  color: "white",
  background: "#471AFF",
  backgroundImage:
    "linear-gradient( 180deg, rgb(255 255 255 / 16%) 0%, rgb(255 255 255 / 0%) 100% )",
  borderRadius: "8px",
  maxHeight: "50px",
};

const descriptionStyle = {
  margin: "0.2rem 0 1rem 1rem",
};

const exerciseItem = {
  margin: "2rem 0",
};

export {
  trainingDetailSection,
  partOfTrainingStyle,
  descriptionStyle,
  titleStyle,
  openVideoBtn,
  exerciseItem,
};
