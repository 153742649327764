import { useState, useCallback } from "react";
import { Box, Button } from "@mui/material";
//Style
import {
  surveyPage,
  mainContentSection,
  btnBox,
  startBtn,
  buttonsBox,
  nextPageBtn,
} from "./style";
//Components
import { ProgressBar } from "../../components";
import { RegisterPage } from "../register";
import {
  StartMessurement,
  ChooseGender,
  Experience,
  AddAge,
  AddMeasures,
  OtherActivities,
  HealthIssue,
  HeartIssue,
  PainIssue,
  Goal,
  PeriodForGoal,
} from "./sections";
import { BackButton } from "../../components";
import { BloodPresure } from "./sections/bloodPresure";
import { NewLogo } from "../../assets";

const sections = [
  "merenje",
  "pol",
  "godine",
  "dodajMere",
  "iskustvo",
  "drugeAktivnosti",
  "zdravstveniProblem",
  "srcaniProblem",
  "krvniPritisak",
  "bolPovreda",
  "cilj",
  "periodCilj",
  "registrujSe",
];

export const SurveyPage = () => {
  const [paginationNumber, setPaginationNumber] = useState<number>(0);
  const [meassures, setMeassures] = useState<any>({
    gender: "",
    age: "",
    height: "",
    weight: "",
    experience: "",
    otherActivities: "",
    healthIssue: "",
    heartIssue: "",
    bloodPresure: "",
    painInjury: "",
    goal: "",
    periodForGoal: "",
  });

  const onSetMeassuresHandler = (key: string, value: string) => {
    setMeassures((prevState: any) => ({ ...prevState, [key]: value }));
  };

  const percentageStep = 100 / sections.length;
  const [progressPercentage, setProgressPercentage] =
    useState<number>(percentageStep);
  const [activeSection, setActiveSection] = useState<string>(
    sections[paginationNumber]
  );

  const isButtonForNextPageAvailable = sections.length > paginationNumber + 1;

  const renderSection = () => {
    switch (activeSection) {
      case "merenje":
        return <StartMessurement />;
      case "pol":
        return (
          <ChooseGender
            activeItem={meassures.gender}
            onClick={onSetMeassuresHandler}
          />
        );
      case "godine":
        return (
          <AddAge onChange={onSetMeassuresHandler} value={meassures.age} />
        );
      case "iskustvo":
        return (
          <Experience
            onChange={onSetMeassuresHandler}
            value={meassures.experience}
          />
        );
      case "drugeAktivnosti":
        return (
          <OtherActivities
            onChange={onSetMeassuresHandler}
            value={meassures.otherActivities}
          />
        );
      case "zdravstveniProblem":
        return (
          <HealthIssue
            onChange={onSetMeassuresHandler}
            value={meassures.healthIssue}
          />
        );
      case "srcaniProblem":
        return (
          <HeartIssue
            onChange={onSetMeassuresHandler}
            value={meassures.heartIssue}
          />
        );
      case "krvniPritisak":
        return (
          <BloodPresure
            onClick={onSetMeassuresHandler}
            value={meassures.bloodPresure}
          />
        );
      case "bolPovreda":
        return (
          <PainIssue
            onChange={onSetMeassuresHandler}
            value={meassures.painInjury}
          />
        );
      case "cilj":
        return <Goal onChange={onSetMeassuresHandler} value={meassures.goal} />;
      case "periodCilj":
        return (
          <PeriodForGoal
            onChange={onSetMeassuresHandler}
            value={meassures.periodForGoal}
          />
        );
      case "dodajMere":
        return (
          <AddMeasures
            onChange={onSetMeassuresHandler}
            height={meassures.height}
            weight={meassures.weight}
          />
        );
      case "registrujSe":
        return <RegisterPage />;

      default:
        return null;
    }
  };

  const nextPage = useCallback(() => {
    setPaginationNumber((prevState) => prevState + 1);
    setActiveSection(sections[paginationNumber + 1]);
    setProgressPercentage((prevState) => prevState + percentageStep);
  }, [paginationNumber]);

  const previousPage = useCallback(() => {
    setPaginationNumber((prevState) => prevState - 1);
    setActiveSection(sections[paginationNumber - 1]);
    setProgressPercentage((prevState) => prevState - percentageStep);
  }, [paginationNumber]);

  return (
    <>
      <ProgressBar progressPercentage={progressPercentage} />
      <Box sx={surveyPage}>
        {activeSection === "merenje" ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BackButton />
          </Box>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <NewLogo />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={mainContentSection}>{renderSection()}</Box>
          <Box>
            {activeSection === "merenje" ? (
              <Box sx={btnBox}>
                <Button variant="contained" sx={startBtn} onClick={nextPage}>
                  Zapocni
                </Button>
              </Box>
            ) : (
              <Box sx={buttonsBox}>
                <Button variant="outlined" onClick={previousPage}>
                  Nazad
                </Button>
                {isButtonForNextPageAvailable ? (
                  <Button
                    variant="contained"
                    sx={nextPageBtn}
                    onClick={nextPage}
                  >
                    Napred
                  </Button>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
