const textBox = {
  marginTop: "6rem",
  display: "flex",
  maxWidth: "450px",
  alignItems: "center",
  flexDirection: "column",
};

const inputBox = {
  marginTop: "1.2rem",
};

export { textBox, inputBox };
