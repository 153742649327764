import { Box, TextField, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
//Components
//Style
import {
  wrapper,
  form,
  inputField,
  submitButton,
  createAccountBox,
  makeRequest,
  logoBox,
} from "./style";
//Config
import { SURVEY_PAGE_PATH } from "../../config";
//Services
import { useAuthenticationContext } from "../../context";
import { useCallback, useState } from "react";
import { NewLogo } from "../../assets";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuthenticationContext();

  const [values, setValues] = useState<any>({
    email: "",
    password: "",
  });

  const isDisabled = !values.email.length || !values.password.length;

  const onChangeHandler = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setValues((prevState: any) => ({ ...prevState, [name]: value }));
    },
    [values, setValues]
  );

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    try {
      const loginSuccess = await login(values.email, values.password);
      if (loginSuccess) {
        navigate("/");
      } else {
        console.log("Login failed, not navigating");
      }
    } catch (err) {
      console.error("Error in onSubmitHandler", err);
    }
  };
  return (
    <Box sx={wrapper}>
      <Box sx={logoBox}>
        <NewLogo />
      </Box>
      <Box component="form" sx={form} noValidate autoComplete="off">
        <TextField
          sx={inputField}
          label="Email"
          name="email"
          onChange={onChangeHandler}
          size="medium"
        />
        <TextField
          sx={inputField}
          label="Password"
          name="password"
          onChange={onChangeHandler}
          size="medium"
        />
      </Box>

      <Box sx={createAccountBox}>
        <Button
          sx={submitButton}
          type="submit"
          variant="contained"
          onClick={onSubmitHandler}
          color="primary"
          size="medium"
          disabled={isDisabled}
        >
          Prijavi se
        </Button>
        <Box
          sx={{
            display: "flex",
            marginTop: "1.5rem",
            justifyContent: "center",
          }}
        >
          <Typography>Nemaš nalog?</Typography>
          <Link to={SURVEY_PAGE_PATH}>
            <Typography color="primary" sx={makeRequest}>
              Napravi zahtev
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
