import { useCallback, useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Config
import { EXERCISES, ADD } from "../../../config";
//Types
import { ExerciseType } from "../../../types";
//Style
import {
  page,
  heading,
  headingBox,
  searchBarBox,
  addNewExcerciseBtn,
} from "./style";
//Components
import { Layout } from "../../../layout";
import { SelectorTrainingBar } from "../../user/userTrainingDetailPage/components";
import { SearchBar } from "../../../components";
import { ListOfTrainingItems } from "../components";
import { AddIcon } from "../../../assets";
import { SECONDARY_COLOR } from "../../../styles";
//Services
import { ExerciseServices } from "../../../services";
//Config
import { TRENING, VEZBE_ISTEZANJA, VEZBE_ZAGREVANJA } from "../../../config";

type Props = {
  navItems: { title: string; path: string }[];
};

const data = [VEZBE_ZAGREVANJA, TRENING, VEZBE_ISTEZANJA];

export const ExercisesPage = ({ navItems }: Props) => {
  const [exercises, setExercises] = useState<ExerciseType[]>();
  const [selectedTraining, setSelectedTraining] = useState<string>(data[0]);
  const navigate = useNavigate();

  useEffect(() => {
    ExerciseServices.getAll().then((response) => {
      setExercises(response);
    });
  }, []);

  const onSelectTrainingHandler = useCallback(
    (partOfTraining: string) => {
      setSelectedTraining(partOfTraining);
    },
    [selectedTraining]
  );

  const filteredData = exercises?.filter(
    (item) => item.category === selectedTraining
  );

  const onAddNewExcerciseHandler = () => {
    navigate(`${EXERCISES}/${ADD}`);
  };

  return (
    <Layout adminNavItems={navItems}>
      <Box sx={page}>
        <Box sx={headingBox}>
          <Typography sx={heading} variant="h1">
            Vežbe
          </Typography>
          <Button
            sx={addNewExcerciseBtn}
            variant="contained"
            onClick={onAddNewExcerciseHandler}
          >
            <AddIcon width="25" height="25" fill={SECONDARY_COLOR} />
            Dodaj novu vežbu
          </Button>
        </Box>
        <SelectorTrainingBar
          data={data}
          selectedTraining={selectedTraining}
          onClick={onSelectTrainingHandler}
        />
        <Box sx={searchBarBox}>
          <SearchBar label="Pretraži..." />
        </Box>

        <ListOfTrainingItems data={filteredData} isExercisePage={true} />
      </Box>
    </Layout>
  );
};
