import { Box, Typography } from "@mui/material";
//Assets
import { CloseIcon } from "../../../../../assets";
//Style
import { labelStyle, iconBox } from "./style";

type Props = {
  label: string;
  onRemove: (label: string) => void;
};

export const ResumeLabel = ({ label, onRemove }: Props) => {
  const onRemoveHandler = () => {
    onRemove(label);
  };

  return (
    <Box sx={labelStyle}>
      <Typography>{label}</Typography>
      <Box onClick={onRemoveHandler} sx={iconBox}>
        <CloseIcon width="15" height="15" />
      </Box>
    </Box>
  );
};
