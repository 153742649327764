const contactSectionImage = require("../../assets/images/showcase.jpg");

//FAQ SECTION
const faqSectionWrapper = {
  display: "flex",
  width: "90%",
  maxWidth: "1200px",
  margin: "3rem auto",
};

//CONTACT SECTION
const contactSection = {
  position: "relative",
  width: "100%",
  height: "850px",
  background: `url(${contactSectionImage}) center left`,
  backgroundSize: "cover",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "::after": {
    content: '""',
    zIndex: "-1",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(16, 16, 17, 0.85)",
  },
};

const contactFormWrapper = {
  background: "white",
  maxWidth: "90%",
  width: "500px",
  borderRadius: "8px",
  boxShadow: "0px 2px 3px 0px #471aff",
};

const contactFormTextBox = {
  width: "40%",
};

const iconsBoxWrapper = {
  display: "flex",
  width: "150px",
  marginTop: "0.5rem",
  alignItems: "center",
  justifyContent: "space-between",
};

const contactFormTitle = {
  fontSize: "4rem",
  lineHeight: "4rem",
  fontWeight: "bold",
  marginBottom: "2rem",
  color: "white",
};

export {
  faqSectionWrapper,
  contactSection,
  contactFormWrapper,
  contactFormTextBox,
  iconsBoxWrapper,
  contactFormTitle,
};
