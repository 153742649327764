import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Assets
import { AddIcon } from "../../../../../assets";
//Style
import { addBox } from "./style";
//Config
import { CUSTOMER, TRAINING } from "../../../../../config";
import { SECONDARY_COLOR } from "../../../../../styles";

type Props = {
  userId?: string;
};

export const AddTrainingBox = ({ userId }: Props) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`${CUSTOMER}/${TRAINING}/${userId}`);
  };
  return (
    <Box sx={addBox} onClick={onClickHandler}>
      <AddIcon fill={SECONDARY_COLOR} />
    </Box>
  );
};
