import { Box, Typography, TextField } from "@mui/material";
//Config
import { painQuestion } from "../../../../config";
//Style
import { textBox, inputField } from "./style";
import { title, sectionBoxWrapper } from "../../style";

type Props = {
  onChange: (key: string, value: string) => void;
  value: string;
};

export const PainIssue = ({ onChange, value }: Props) => {
  const key = "painInjury";

  const onChangeHandler = (key: string, value: string) => {
    onChange(key, value);
  };
  return (
    <Box sx={sectionBoxWrapper}>
      <Typography sx={title} variant="h2">
        {painQuestion}
      </Typography>

      <Box sx={textBox}>
        <TextField
          sx={inputField}
          multiline
          rows={3}
          onChange={(e) => onChangeHandler(key, e.target.value)}
          value={value}
        />
      </Box>
    </Box>
  );
};
