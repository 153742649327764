const pageStyle = {
  padding: "3rem 2.5rem",
  width: "100%",
  backgrund: "rgba(0,0,0,0.2)",
};

const header = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2rem",
};

const heading = {
  fontSize: "2.8rem",
  fontWeight: "bold",
};

export { pageStyle, header, heading };
