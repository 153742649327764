import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Alert,
} from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { BackButton } from "../../../components";
import { Dropzone } from "../../../components";
import { PredefinedLabelsForExcercise } from "../../../components";
//Style
import {
  page,
  header,
  heading,
  categoryDropdown,
  nameOfExerciseBox,
  nameOfExerciseInputField,
  addDescriptionBox,
  descriptionInputField,
  addDescriptionBtn,
  addVideoBox,
  createExerciseBtn,
  predefinedNotesBox,
} from "./style";
//Services
import { ExerciseServices } from "../../../services";
//Utils
import { scrollToTop } from "../../../utils";

type Props = {
  navItems: { title: string; path: string }[];
};

type NewExercise = {
  category: string;
  description: string;
  videoURL: string;
};
const data = ["Zagrevanje", "Trening", "Istezanja"];

export const AddNewExercise = ({ navItems }: Props) => {
  const [isSuccessfullUpdate, setIsSuccessfullUpdate] = useState<string>("");
  const [isError, setIsError] = useState<string>("");

  const [predefinedNotes, setPredefinedNotes] = useState<string[]>([]);
  const [newExercise, setNewExercise] = useState<NewExercise>({
    category: "",
    description: "",
    videoURL: "test",
  });

  const [note, setNote] = useState<string>("");

  const onChangeNoteHandler = (e: any) => {
    setNote(e.target.value);
  };

  const onAddNoteHandler = () => {
    setPredefinedNotes((prevState) => [...prevState, note]);
    setNote("");
  };

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;

    setNewExercise((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const onCreateExerciseHandler = () => {
    try {
      ExerciseServices.create({
        ...newExercise,
        defaultDescription: predefinedNotes.join(";"),
      });
      setIsSuccessfullUpdate("Uspešno kreirana vežba!");

      setNewExercise({
        category: "",
        description: "",
        videoURL: "",
      });
      setPredefinedNotes([]);
    } catch (err) {
      console.error(err);
      setIsError("Greška prilikom kreiranja vežbe");
    } finally {
      scrollToTop();
    }
  };

  return (
    <Layout adminNavItems={navItems}>
      <Box sx={page}>
        {isSuccessfullUpdate && (
          <Alert
            sx={{
              position: "absolute",
              top: "10",
              width: "600px",
              maxWidth: "90%",
            }}
            severity="success"
          >
            {isSuccessfullUpdate}
          </Alert>
        )}
        {isError && (
          <Alert sx={{ position: "absolute", top: "10" }} severity="error">
            {isError}
          </Alert>
        )}
        <Box sx={header} component="header">
          <Typography sx={heading}>Dodaj novu vežbu</Typography>
        </Box>
        <Box>
          <Typography>Izaberi kategoriju</Typography>
          <TextField
            name="category"
            value={newExercise.category}
            select
            sx={categoryDropdown}
            onChange={(e) => onChangeHandler(e)}
          >
            {data.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={nameOfExerciseBox}>
          <Typography>Unesi naziv vežbe</Typography>
          <TextField
            name="description"
            sx={nameOfExerciseInputField}
            value={newExercise.description}
            onChange={(e) => onChangeHandler(e)}
          />
        </Box>
        <Box sx={addDescriptionBox}>
          <Typography>Dodaj opis (jedan po jedan)</Typography>
          <TextField
            value={note}
            onChange={(e) => onChangeNoteHandler(e)}
            multiline
            rows={3}
            sx={descriptionInputField}
          />
          <Button
            variant="contained"
            sx={addDescriptionBtn}
            onClick={onAddNoteHandler}
          >
            Dodaj opis
          </Button>
        </Box>

        {predefinedNotes.length > 0 ? (
          <Box sx={predefinedNotesBox}>
            <PredefinedLabelsForExcercise predefinedNotes={predefinedNotes} />
          </Box>
        ) : null}

        <Box sx={addVideoBox}>
          <Typography>Dodaj video</Typography>
          <Dropzone />
        </Box>

        <Button
          sx={createExerciseBtn}
          variant="contained"
          onClick={onCreateExerciseHandler}
        >
          Kreiraj vežbu
        </Button>
      </Box>
    </Layout>
  );
};
