export const getMonthBasedOnTimestamp = (timestamp: string) => {
  const monthNames = [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "Avgust",
    "Septembar",
    "Oktobar",
    "Novembar",
    "Decembar",
  ];

  const date = new Date(timestamp);
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  return monthName;
};
