import { Box, Typography } from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { BackButton } from "../../../components";
import { EditCustomerForm } from "./components";
//Style
import { page, header, heading } from "./style";

type Props = {
  navItems: { title: string; path: string }[];
};

export const EditCustomerPage = ({ navItems }: Props) => {
  return (
    <Layout adminNavItems={navItems}>
      <Box sx={page} component="main">
        <Box component="header" sx={header}>
          <Typography variant="h1" sx={heading}>
            Izmeni
          </Typography>
          <BackButton />
        </Box>
        <Box>
          <EditCustomerForm />
        </Box>
      </Box>
    </Layout>
  );
};
