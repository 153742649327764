type Props = {
  width?: string;
  height?: string;
  fill?: string;
};

export const ArrowLeftIcon = ({ width = "24", height = "24", fill }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
      fill={fill}
    >
      <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
    </svg>
  );
};
