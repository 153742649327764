import { useState, useEffect } from "react";
import { Box, Typography, TextField, Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
//Components
import { Calendar } from "../../../../../components";
//Style
import {
  form,
  formHeader,
  inputField,
  formDates,
  dateTitle,
  dateRightBox,
  formFooter,
  editBox,
  editBtn,
} from "./style";
//Services
import { UserServices } from "../../../../../services";

//Types
import { UserType } from "../../../../../types";

export const EditCustomerForm = () => {
  const { customerId } = useParams();
  const [isDisabledEditButton, setIsDisabledEditButton] =
    useState<boolean>(true);

  const [editedUser, setEditedUser] = useState<UserType | any>({
    fullName: "",
    packageType: "",
    startDate: "",
    endDate: "",
    sumOfPaidMonths: "",
    priceForThisMonth: "",
    sumPrice: "",
    email: "",
  });

  useEffect(() => {
    if (!customerId) return;
    UserServices.getById(customerId).then((response) => {
      setEditedUser(response);
    });
  }, []);

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setEditedUser((prevState: UserType) => ({ ...prevState, [name]: value }));
    setIsDisabledEditButton(false);
  };

  const onChangeStartDate = (value: string) => {
    setEditedUser((prevState: UserType) => ({
      ...prevState,
      startDate: value,
    }));
    setIsDisabledEditButton(false);
  };

  const onChangeEndDate = (value: string) => {
    setEditedUser((prevState: UserType) => ({
      ...prevState,
      endDate: value,
    }));
    setIsDisabledEditButton(false);
  };

  const onEditUserHandler = () => {
    try {
      UserServices.edit(editedUser);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={form}>
      <Box sx={formHeader}>
        <TextField
          onChange={(e) => onChangeHandler(e)}
          label="Ime i prezime"
          name="fullName"
          sx={inputField}
          value={editedUser?.fullName}
        />
        <TextField
          onChange={(e) => onChangeHandler(e)}
          label="Email"
          name="email"
          sx={inputField}
          value={editedUser?.email}
          disabled
        />
      </Box>

      <Divider />

      <Box sx={formDates}>
        <Box>
          <Typography sx={dateTitle}>Izaberi početak programa:</Typography>
          <Calendar
            value={editedUser?.startDate}
            onChange={onChangeStartDate}
          />
        </Box>
        <Box sx={dateRightBox}>
          <Typography sx={dateTitle}>Izaberi kraj programa:</Typography>
          <Calendar value={editedUser?.endDate} onChange={onChangeEndDate} />
        </Box>
      </Box>

      <Divider />

      <Box sx={formFooter}>
        <TextField
          onChange={(e) => onChangeHandler(e)}
          sx={inputField}
          label="Tip Paketa"
          value={editedUser?.packageType}
          name="packageType"
        />
        <TextField
          onChange={(e) => onChangeHandler(e)}
          sx={inputField}
          label="Ukupno plaćenih meseci"
          value={editedUser?.sumOfPaidMonths}
          name="sumOfPaidMonths"
        />
        <TextField
          onChange={(e) => onChangeHandler(e)}
          sx={inputField}
          label="Cena paketa za ovaj mesec"
          value={editedUser?.priceForThisMonth}
          name="priceForThisMonth"
        />
        <TextField
          onChange={(e) => onChangeHandler(e)}
          sx={inputField}
          label="Ukupno"
          value={editedUser?.sumPrice}
          name="sumPrice"
        />
      </Box>

      <Box sx={editBox}>
        <Button
          disabled={isDisabledEditButton}
          sx={editBtn}
          variant="contained"
          onClick={onEditUserHandler}
        >
          Izmeni
        </Button>
      </Box>
    </Box>
  );
};

export default EditCustomerForm;
