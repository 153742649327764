const userHomePage = {
  background: "rgb(249,249,249)",
  width: "100%",
  minHeight: "100vh",
  padding: "2rem",
};
const heading = {
  fontSize: "2rem",
  fontWeight: "bold",
};

const daysList = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  maxWidth: "1000px",
  height: "auto",
  marginTop: "24px",
};

export { userHomePage, heading, daysList };
