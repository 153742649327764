import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../../../styles";

const form = {
  padding: "2rem 0",
};

const formHeader = {
  display: "flex",
  padding: "2rem 0",
};

const inputField = {
  width: "350px",
  margin: "0.5rem",
};

const formDates = {
  display: "flex",
  padding: "3rem 0",
};

const dateTitle = {
  fontSize: "1.1rem",
  marginBottom: "1rem",
};

const dateRightBox = {
  marginLeft: "2rem",
};

const formFooter = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  padding: "2rem 0",
};

const editBox = {
  marginTop: "2rem",
};

const editBtn = {
  fontSize: "1rem",
  background: SECONDARY_COLOR,
  color: PRIMARY_COLOR,
  borderRadius: "8px",
  display: "inline-block",
  padding: "0.6rem 6rem",
  textTransform: "capitalize",
  "&:hover": {
    background: SECONDARY_COLOR,
    opacity: "0.9",
  },
};

export {
  form,
  formHeader,
  inputField,
  formDates,
  dateTitle,
  dateRightBox,
  formFooter,
  editBox,
  editBtn,
};
