import { TableRow, TableCell } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Config
import { CUSTOMER } from "../../../../config";
//Style
import { rowStyle } from "./style";
//Components
import { MenuPopover } from "../../../../components";

type Props = {
  id: string;
  fullname: string;
  selectedPackage?: string;
  startDate?: string;
  endDate?: string;
  sumOfMonths?: string;
  pricePerMonth?: string;
  sumPrice?: string;
};

export const TableRowComponent = ({
  id,
  fullname,
  selectedPackage,
  startDate,
  endDate,
  sumOfMonths,
  pricePerMonth,
  sumPrice,
}: Props) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(`${CUSTOMER}/${id}`);
  };

  return (
    <TableRow hover role="checkbox" sx={rowStyle} onClick={onClickHandler}>
      <TableCell align={"left"}>{fullname}</TableCell>
      <TableCell align={"left"}>{selectedPackage}</TableCell>
      <TableCell align={"left"}>{startDate}</TableCell>
      <TableCell align={"left"}>{endDate}</TableCell>
      <TableCell align={"left"}>{sumOfMonths}</TableCell>
      <TableCell align={"left"}>{pricePerMonth}€</TableCell>
      <TableCell align={"left"}>{sumPrice}€</TableCell>
      <TableCell>
        <MenuPopover userId={id} />
      </TableCell>
    </TableRow>
  );
};
