import { Box, TextField, Typography } from "@mui/material";
//Config
import { experienceInTrainingQuestion } from "../../../../config";
//Style
import { textBox, inputField } from "./style";
import { title } from "../../style";

type Props = {
  onChange: (key: string, value: string) => void;
  value: string;
};

export const Experience = ({ onChange, value }: Props) => {
  const key = "experience";

  const onChangeHandler = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: "100%" }}>
        <Typography sx={title} variant="h2">
          {experienceInTrainingQuestion}
        </Typography>
      </Box>

      <Box sx={textBox}>
        <TextField
          sx={inputField}
          multiline
          rows={3}
          value={value}
          onChange={(e) => onChangeHandler(key, e.target.value)}
        />
      </Box>
    </Box>
  );
};
