import apiInstance from "./api";

const login = async (user: any) => {
  try {
    const response = await apiInstance.post(`/auth/login`, user);
    return response;
  } catch (err) {
    return {};
  }
};

export const AuthService = {
  login,
};
