import { Box, Typography } from "@mui/material";
import { exerciseItemFooter } from "./style";
import { GREY_COLOR } from "../../../styles";

export const ExerciseItemFooter = () => {
  return (
    <Box sx={exerciseItemFooter}>
      <Box>
        <Typography sx={{ color: "#ffffff", paddingRight: "1rem" }}>
          100kg
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          borderLeft: "1px solid grey",
          borderRight: "1px solid grey",
        }}
      >
        <Typography sx={{ color: GREY_COLOR }}>4 serije</Typography>
      </Box>
      <Box>
        <Typography sx={{ color: GREY_COLOR, paddingLeft: "1rem" }}>
          8 ponavljanja
        </Typography>
      </Box>
    </Box>
  );
};
