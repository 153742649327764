import { TableCell, TableHead, TableRow } from "@mui/material";
//Style
import { tableRow, tableCell } from "./style";

type Props = {
  tableHeadItems: {
    id: number;
    label: string;
    align: string;
  }[];
};

export const TableHeadComponent = ({ tableHeadItems }: Props) => {
  return (
    <TableHead>
      <TableRow sx={tableRow}>
        {tableHeadItems.map((column) => (
          <TableCell sx={tableCell} key={column.id} align="left">
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
