import { useState, useEffect } from "react";
//Services
import { TrainingServices } from "../services";
//Types
import { TrainingType } from "../types";

export const useGetTrainingById = (id: string) => {
  const [training, setTraining] = useState<TrainingType | null>(null);

  useEffect(() => {
    TrainingServices.getById(id).then((response) => {
      // Flatten the exercises array by merging exercise fields into the main object
      const updatedExercises = response.exercises.map((exerciseData: any) => {
        const { exercise, ...rest } = exerciseData; // Separate the nested exercise object
        return { ...rest, ...exercise }; // Merge the nested exercise into the outer object
      });

      // Update the training data with the flattened exercises
      setTraining({
        ...response,
        exercises: updatedExercises,
      });
    });
  }, [id]);
  return { training };
};
