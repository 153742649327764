const textBox = {
  marginTop: "6rem",
  display: "flex",
  maxWidth: "90%",
  alignItems: "center",
  flexDirection: "column",
};

const inputField = {
  width: "450px",
  maxWidth: "100%",
};

export { textBox, inputField };
