import { Box, Typography, Divider } from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { QuestionAndAnswerItem } from "./components";
import { BackButton } from "../../../components";
//Config
import {
  fullNameQuestion,
  emailQuestion,
  genderQuestion,
  ageQuestion,
  heightOverviewAnswer,
  weightOverviewAnswer,
  experienceInTrainingQuestion,
  helathIssuesQuestion,
  otherActivitiesQuestion,
  healthHearthIssueQuestion,
  painQuestion,
  bloodPresureQuestion,
  goalQuestion,
  periodOfTimeForGoalQuestion,
} from "../../../config";
//Style
import { pageStyle, header, heading } from "./style";
import { useParams } from "react-router-dom";
import { useGetUserById } from "../../../hooks";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerDetailSurveyPage = ({ navItems }: Props) => {
  const { id } = useParams();

  const { user } = useGetUserById(id!);

  const gender = user?.meassures?.gender;
  const age = user?.meassures?.age;
  const height = user?.meassures?.height;
  const weight = user?.meassures?.weight;
  const experience = user?.meassures?.experience;
  const otherActivities = user?.meassures?.otherActivities;
  const healthIssue = user?.meassures?.healthIssue;
  const heartIssue = user?.meassures?.heartIssue;
  const bloodPresure = user?.meassures?.bloodPresure;
  const painInjury = user?.meassures?.painInjury;
  const goal = user?.meassures?.goal;
  const periodForGoal = user?.meassures?.periodForGoal;
  console.log(user, "User");
  return (
    <Layout adminNavItems={navItems}>
      <Box sx={pageStyle}>
        <Box sx={header} component="header">
          <Typography sx={heading} variant="h1">
            Anketa
          </Typography>
          <BackButton />
        </Box>
        <Box>
          <QuestionAndAnswerItem
            question={fullNameQuestion}
            answer={user?.fullName}
          />
          <QuestionAndAnswerItem
            question={emailQuestion}
            answer={user?.email}
          />
          <QuestionAndAnswerItem question={genderQuestion} answer={gender} />
          <QuestionAndAnswerItem question={ageQuestion} answer={age} />
        </Box>
        <Divider />

        <Box>
          <QuestionAndAnswerItem
            question={heightOverviewAnswer}
            answer={height}
          />
          <QuestionAndAnswerItem
            question={weightOverviewAnswer}
            answer={weight}
          />
          <QuestionAndAnswerItem
            question={experienceInTrainingQuestion}
            answer={experience}
          />
          <QuestionAndAnswerItem
            question={otherActivitiesQuestion}
            answer={otherActivities}
          />
          <QuestionAndAnswerItem
            question={helathIssuesQuestion}
            answer={healthIssue}
          />
          <QuestionAndAnswerItem
            question={healthHearthIssueQuestion}
            answer={heartIssue}
          />
          <QuestionAndAnswerItem
            question={bloodPresureQuestion}
            answer={bloodPresure}
          />
          <QuestionAndAnswerItem question={painQuestion} answer={painInjury} />
          <QuestionAndAnswerItem question={goalQuestion} answer={goal} />
          <QuestionAndAnswerItem
            question={periodOfTimeForGoalQuestion}
            answer={periodForGoal}
          />
        </Box>
      </Box>
    </Layout>
  );
};
