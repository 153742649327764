const page = {
  width: "100%",
  padding: "3rem 2rem",
};

const header = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2rem",
};

const heading = {
  fontSize: "2.2rem",
  fontWeight: "bold",
};

export { page, header, heading };
