import { Box, Theme, styled } from "@mui/material";

type StylesTypes = {
  isActive: boolean;
  theme: Theme;
};

const textBox = {
  marginTop: "6rem",
  display: "flex",
};

const optionText = {
  fontSize: "1rem",
};

const StyledOptionLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive, theme }: StylesTypes) => ({
  padding: "0.8rem 1rem",
  border: "1px solid grey",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "200px",
  margin: "0 0.8rem",
  cursor: "pointer",
  background: isActive ? theme.palette.grey[300] : theme.palette.common.white,
}));

export { textBox, optionText, StyledOptionLabel };
