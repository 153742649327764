import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
//Components
import { Layout } from "../../../layout";
import { DayCard } from "../../../components";
import { BackButton } from "../../../components";
import { InfoBox } from "../customerDetailPage/components/infoBox";
//Hooks
import { useGetUserById } from "../../../hooks";
//Style
import { page, pageHeader, heading, list } from "./style";
//Utils
import {
  getMonthBasedOnTimestamp,
  capitalizeFirstLetter,
} from "../../../utils";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerAccHistoryForSpecificMonth = ({ navItems }: Props) => {
  //Ovo koristis ako si super admin, a ako si user onda ces koristiti svoj id
  const { id, month } = useParams();
  const { user } = useGetUserById(id!);

  const filteredTrainingsByMonth = user?.trainings.filter((training) => {
    return getMonthBasedOnTimestamp(training.date).toLowerCase() === month;
  });

  console.log(filteredTrainingsByMonth);

  //Sustina je da ovde getujem sve vezbe za usera, malo je drugacije kada nije admin u pitanju i to obrati paznju
  //zatim filtriram po mesecu, kasnije ubaci i po godinama

  return (
    <Layout userNavItems={navItems}>
      <Box sx={page}>
        <Box sx={pageHeader}>
          <Box>
            <Typography sx={heading}>
              {capitalizeFirstLetter(month!)}
            </Typography>
            <Typography>
              Broj treninga: {filteredTrainingsByMonth?.length}
            </Typography>
          </Box>

          <BackButton />
        </Box>
        <InfoBox
          fullName={user?.fullName}
          selectedPackage={user?.packageType}
          startDate={user?.startDate}
          endDate={user?.endDate}
        />
        <Box sx={list}>
          {filteredTrainingsByMonth?.map((item) => (
            <DayCard
              isAdmin={true}
              date={item?.date}
              userId={id}
              trainingId={item?.id}
              trainingSummary={item?.trainingSummary}
            />
          ))}
        </Box>
      </Box>
    </Layout>
  );
};
