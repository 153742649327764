import { Box } from "@mui/material";

type Props = {
  progressPercentage: number;
};

export const ProgressBar = ({ progressPercentage }: Props) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: `${progressPercentage}%`,
          borderRadius: " 0 8px  8px 0",
          transition: "all ease-out 0.3s",
          height: "10px",
          background: "#471AFF",
          backgroundImage:
            "linear-gradient( 180deg, rgb(255 255 255 / 16%) 0%, rgb(255 255 255 / 0%) 100% )",
        }}
      ></Box>
    </Box>
  );
};
