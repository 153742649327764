import { Box, Typography } from "@mui/material";
//Style
import { addDescriptionPreview, textStyle } from "./style";

type Props = {
  description?: string;
};

export const AddDescriptionPreview = ({ description }: Props) => {
  return (
    <Box sx={addDescriptionPreview}>
      <Typography sx={textStyle}>{description}</Typography>
    </Box>
  );
};
