import { PRIMARY_COLOR } from "../../styles/colorPallete";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

type StylesTypes = {
  isOpen: boolean;
  isMobile: boolean;
};

const StyledSidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "isMobile",
})(({ isOpen, isMobile }: StylesTypes) => ({
  height: "100vh",
  width: "300px",
  minWidth: "300px",
  maxWidth: "90%",
  borderRight: "1px dotted gray",
  background: PRIMARY_COLOR,

  position: isMobile ? "fixed" : "sticky",
  left: 0,
  top: 0,
  bottom: 0,
  transform: !isOpen && isMobile ? "translateX(-100%)" : "translateX(0)",
  transition: isMobile ? "transform 0.3s ease" : "none",
  zIndex: 10,
}));

export { StyledSidebar };
