export const getDayBasedOnTimestamp = (timestamp: string) => {
  const dateObj = new Date(timestamp);
  const daysOfWeek = [
    "Nedelja",
    "Ponedeljak",
    "Utorak",
    "Sreda",
    "Četvrtak",
    "Petak",
    "Subota",
  ];

  const dayOfWeek = daysOfWeek[dateObj.getDay()];

  return dayOfWeek;
};
