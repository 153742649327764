export const PlayIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill="#67CCCC" />
      <path
        d="M15.9543 10.6763C17.0152 11.2532 17.0152 12.7468 15.9543 13.3237L9.54831 16.8073C8.51718 17.368 7.25 16.6382 7.25 15.4836L7.25 8.51645C7.25 7.36184 8.51718 6.63201 9.54831 7.19274L15.9543 10.6763Z"
        fill="white"
      />
    </svg>
  );
};
