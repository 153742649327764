import { Typography, Box } from "@mui/material";
//Style
import { textBox, text } from "./style";
import { title } from "../../style";

export const StartMessurement = () => {
  return (
    <Box>
      <Typography variant="h1" sx={title}>
        Merenje
      </Typography>
      <Box sx={textBox}>
        <Typography sx={text} variant="body1">
          ako bismo mogli da napravimo najbolji mogući program za tebe, potrebno
          je da što preciznije odgovoriš na pitanja koja slede.
        </Typography>
        <Typography sx={text} variant="body1">
          Ovim putem praviš i zahtev za kreiranje naloga tako što ćeš na kraju
          ankete ostaviti kontakt podatke
        </Typography>
      </Box>
    </Box>
  );
};
