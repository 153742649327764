const form = {
  display: "block",
  // flexDirection: "column",
};

const inputField = {
  marginTop: "1rem",
  width: "100%",
};

const textareaFiled = {
  borderRadius: "12px 12px 0 12px",
  padding: "16.5px 14px",
};

const submitButton = {
  fontSize: "1rem",
  width: "100%",
  margin: "4rem auto 1rem auto",
  padding: "0.8rem 0",
  color: "white",
  borderRadius: "8px",
};

export { form, textareaFiled, submitButton, inputField };
