import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

type Props = {
  value?: any;
  onChange?: (value: string) => void;
};

export const Calendar = ({ value, onChange }: Props) => {
  const onChangeHandler = (value: any) => {
    if (onChange) onChange(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(value)}
        onChange={(newValue) => onChangeHandler(newValue)}
      />
    </LocalizationProvider>
  );
};
