import { useState, useCallback } from "react";
import { Box } from "@mui/material";
//Style
import { pageHeader, pageStyle } from "./style";
//Hooks
import { useGetTrainingById, useGetUserById } from "../../../hooks";
//Utils
import {
  getDayBasedOnTimestamp,
  getDateBasedOnTimestamp,
  isMobile,
} from "../../../utils";
//Mock Data
import { userTrainingDetailData } from "../../../mockData/userTrainingDetailPage";
//Components
import { Layout } from "../../../layout";
import {
  InfoBox,
  SelectorTrainingBar,
} from "../../user/userTrainingDetailPage/components";
import { BackButton } from "../../../components";
import { UserTrainingPartDetails } from "../../../sections";
import { useParams } from "react-router-dom";

type Props = {
  navItems: { title: string; path: string }[];
};

export const CustomerAccHistoryForSpecificDay = ({ navItems }: Props) => {
  const data = ["Zagrevanje", "Trening", "Istezanje"];
  const { trainingId } = useParams();
  const { training } = useGetTrainingById(trainingId!);

  console.log(training);
  console.log(trainingId, "id training");

  const { id } = useParams();
  const isMobileDevice = isMobile();

  const [selectedTraining, setSelectedTraining] = useState<string>(data[0]);

  const onSelectTrainingHandler = useCallback(
    (partOfTraining: string) => {
      setSelectedTraining(partOfTraining);
    },
    [selectedTraining]
  );

  //Na osnovu id-a iz paramsa isfiltriraj trening i prosledi ga na 66. liniju
  const detailTrainingPartData = training?.exercises?.filter(
    (item) => item.category === selectedTraining
  );

  return (
    <Layout adminNavItems={navItems}>
      <Box sx={pageStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SelectorTrainingBar
            data={data}
            selectedTraining={selectedTraining}
            onClick={onSelectTrainingHandler}
          />
          {!isMobileDevice && <BackButton />}
        </Box>

        <Box sx={pageHeader}>
          <InfoBox date={training?.date} content={training?.trainingSummary} />
          {isMobileDevice && <BackButton />}
        </Box>
        <UserTrainingPartDetails
          data={detailTrainingPartData}
          selectedCategory={selectedTraining}
        />
      </Box>
    </Layout>
  );
};
