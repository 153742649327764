import { Box, Typography } from "@mui/material";
//Style
import { infoBoxStyle, heading, divider, valueText, infoText } from "./style";
//Utils
import { getDateBasedOnTimestamp } from "../../../../../utils";

type Props = {
  fullName?: string;
  selectedPackage?: string;
  startDate?: string;
  endDate?: string;
};

export const InfoBox = ({
  fullName,
  selectedPackage,
  startDate,
  endDate,
}: Props) => {
  return (
    <Box sx={infoBoxStyle}>
      <Typography component="h1" sx={heading}>
        {fullName}
      </Typography>
      <Box sx={divider}></Box>
      <Typography sx={infoText}>
        Paket: <Typography sx={valueText}>{selectedPackage}</Typography>
      </Typography>
      <Typography sx={infoText}>
        Početak programa:{" "}
        <Typography sx={valueText}>
          {getDateBasedOnTimestamp(startDate!)}
        </Typography>
      </Typography>
      <Typography sx={infoText}>
        Kraj programa:{" "}
        <Typography sx={valueText}>
          {getDateBasedOnTimestamp(endDate!)}
        </Typography>
      </Typography>
    </Box>
  );
};
