const userTrainingDetailPage = {
  width: "90%",
  maxWidth: "1000px",
  margin: "1rem auto",
};

const pageHeader = {
  display: "flex",
  justifyContent: "space-between",
};

export { userTrainingDetailPage, pageHeader };
