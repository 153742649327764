const page = {
  background: "rgb(249,249,249)",
  width: "100%",
  minHeight: "100vh",
  padding: "2rem",
};

const pageHeader = {
  display: "flex",
  justifyContent: "space-between",
};

const heading = {
  fontSize: "2.5rem",
  fontWeight: "bold",
};

const list = {
  marginTop: "3rem",
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
};

export { page, pageHeader, heading, list };
