import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
//Assets
import { NewBackIcon } from "../../assets";

export const BackButton = () => {
  const navigate = useNavigate();

  const onBackHandler = () => {
    navigate(-1);
  };

  return (
    <Box onClick={onBackHandler}>
      <NewBackIcon />
    </Box>
  );
};
