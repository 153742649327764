import { useState, useCallback } from "react";
import { Box, TextField, Button } from "@mui/material";
//Style
import { form, inputField, submitButton } from "./style";
//Service
import { UserServices } from "../../services";
import { LOGIN_PAGE_PATH } from "../../config";
import { useNavigate } from "react-router-dom";
import { sectionBoxWrapper } from "../survey/style";

export const RegisterPage = () => {
  const [values, setValues] = useState<any>({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const onChangeHandler = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setValues((prevState: any) => ({ ...prevState, [name]: value }));
    },
    [values, setValues]
  );

  const onApplyHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      if (values.password === values.confirmPassword) {
        try {
          UserServices.create({
            fullName: values.fullname,
            email: values.email,
            password: values.password,
            measures: { goal: "20" },
            role: "user",
          });
          navigate(LOGIN_PAGE_PATH);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [values]
  );
  return (
    <Box sx={sectionBoxWrapper}>
      <Box component="form" sx={form} noValidate autoComplete="off">
        <TextField
          onChange={onChangeHandler}
          sx={inputField}
          name="fullname"
          id="fullname"
          label="Ime i Prezime"
        />

        <TextField
          onChange={onChangeHandler}
          name="email"
          sx={inputField}
          id="email"
          label="E-mail Adresa"
        />
        <TextField
          onChange={onChangeHandler}
          name="password"
          type="password"
          sx={inputField}
          id="password"
          label="Lozinka"
        />
        <TextField
          onChange={onChangeHandler}
          name="confirmPassword"
          type="password"
          sx={inputField}
          id="confirm-password"
          label="Potvrdi lozinku"
        />

        <Button
          sx={submitButton}
          type="submit"
          variant="contained"
          onClick={onApplyHandler}
        >
          Prijavi se
        </Button>
      </Box>
    </Box>
  );
};
