import { AZURE_WEB_COLOR, PRIMARY_COLOR } from "../../styles";

const predefinedNotesStyle = {
  background: AZURE_WEB_COLOR,
  padding: "1.5rem",
  margin: "0.5rem 0 1rem 0",
  width: "90%",
  maxWidth: "600px",
  borderRadius: "8px",
};

const labelBox = {
  display: "flex",
  alignItems: "center",
};

const dot = {
  width: "5px",
  height: "5px",
  marginRight: "5px",
  background: PRIMARY_COLOR,
  borderRadius: "50%",
};

export { predefinedNotesStyle, labelBox, dot };
