import { useState, useCallback } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
//Style
import {
  resumeSection,
  textFieldBox,
  addLabelBtn,
  listLabel,
  inputField,
} from "./style";
//Components
import { ResumeLabel } from "../resumeLabel";

type Props = {
  onAddTrainingSummaryHandler?: (trainingSummaryList: string[]) => void;
};

export const ResumeTrainingSection = ({
  onAddTrainingSummaryHandler,
}: Props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [currentValue, setCurrentValue] = useState<any>("");

  const onAddLabel = () => {
    const newLabel = currentValue.trim();
    if (newLabel.length > 0) setLabels((prevState) => [...prevState, newLabel]);
    if (onAddTrainingSummaryHandler)
      onAddTrainingSummaryHandler([...labels, newLabel]);
    setCurrentValue("");
  };

  const onChangeHandler = (e: any) => {
    setCurrentValue(e.target.value);
  };

  const onRemoveLabel = useCallback(
    (removedLabel: string) => {
      const filteredItems = labels.filter((label) => label !== removedLabel);
      setLabels(filteredItems);
    },
    [labels]
  );

  return (
    <Box sx={resumeSection}>
      <Typography>Dodaj rezime treninga:</Typography>
      <Box sx={textFieldBox}>
        <TextField
          sx={inputField}
          onChange={onChangeHandler}
          value={currentValue}
          label="Npr: čučanj"
          variant="outlined"
        />
        <Button sx={addLabelBtn} variant="contained" onClick={onAddLabel}>
          Dodaj
        </Button>
      </Box>
      <Box sx={listLabel}>
        {labels.map((label) => (
          <ResumeLabel key={label} label={label} onRemove={onRemoveLabel} />
        ))}
      </Box>
    </Box>
  );
};
