import { MIDNIGHT_GREEN_COLOR } from "../../../styles";

const titleStyle = {
  fontSize: "1.4rem",
  fontWeight: "600",
  color: MIDNIGHT_GREEN_COLOR,
};

const exerciseItem = {
  margin: "2rem 0",
  border: "1.5px solid #DDEBEC",
  borderRadius: "12px",
  background: "#ffffff",
};

const predefinedNotesStyle = {
  padding: "1rem",
  margin: "0.5rem 0 1rem 0",
  width: "90%",
  maxWidth: "600px",
  borderRadius: "8px",
};

const predefinedNoteItem = {
  display: "flex",
  justifyContent: "flex-start",
  gap: "5px",
  marginTop: "3px",
};

const exerciseItemFooter = {
  background: MIDNIGHT_GREEN_COLOR,
  borderBottomLeftRadius: "12px",
  borderBottomRightRadius: "12px",
  padding: "8px 16px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export {
  exerciseItem,
  titleStyle,
  predefinedNotesStyle,
  predefinedNoteItem,
  exerciseItemFooter,
};
