const logoutBox = {
  display: "flex",
  justifyContent: "space-bewtween",
  alignItems: "center",
};

const logoutTitle = {
  marginLeft: "0.5rem",
  color: "white",
  cursor: "pointer",
};

const popupContentWrapper = {
  width: "400px",
  maxWidth: "90%",
  padding: "2.5rem 1.5rem",
};

const btnBox = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1rem",
};

const btnYes = {
  fontSize: "0.9rem",
  background: "#161617",
  color: "white",
  textTransform: "capitalize",
  padding: "0.3rem 0.8rem",
  borderRadius: "8px",
};

const btnNo = {
  fontSize: "0.9rem",
  background: "white",
  color: "#161617",
  border: "1px solid rgba(145, 158, 171, 0.32)",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "0.3rem 0.8rem",
  marginLeft: "0.5rem",
};

export { logoutBox, logoutTitle, popupContentWrapper, btnBox, btnYes, btnNo };
