import { InputAdornment, TextField } from "@mui/material";
//Style
import { searchBarStyle } from "./style";
//Assets
import { SearchIcon } from "../../assets";

type Props = {
  label: string;
};

export const SearchBar = ({ label }: Props) => {
  return (
    <TextField
      sx={searchBarStyle}
      label={label}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon width="30" height="30" />
          </InputAdornment>
        ),
      }}
    />
  );
};
