import { Box, Typography, useTheme } from "@mui/material";
//Style
import { textBox, optionText } from "./style";
import { title } from "../../style";
//Config
import { genderQuestion } from "../../../../config";
import { StyledOptionLabel } from "../../style";

type Props = {
  onClick: (key: string, value: string) => void;
  activeItem: string;
};

export const ChooseGender = ({ onClick, activeItem }: Props) => {
  const key = "gender";
  const onClickHandler = (key: string, value: string) => {
    onClick(key, value);
  };
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h1" sx={title}>
        {genderQuestion}
      </Typography>
      <Box sx={textBox}>
        <StyledOptionLabel
          onClick={() => onClickHandler(key, "Muško")}
          theme={theme}
          isActive={activeItem === "Muško"}
        >
          <Typography sx={optionText}>Muško</Typography>
        </StyledOptionLabel>
        <StyledOptionLabel
          onClick={() => onClickHandler(key, "Žensko")}
          theme={theme}
          isActive={activeItem === "Žensko"}
        >
          <Typography sx={optionText}>Žensko</Typography>
        </StyledOptionLabel>
      </Box>
    </Box>
  );
};
