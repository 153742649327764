import { Navigate } from "react-router-dom";
import { useAuthenticationContext } from "../context";
import { LOGIN_PAGE_PATH } from "../config";

type Props = {
  element: JSX.Element;
};

export const PrivateRoute = ({ element }: Props) => {
  const { user } = useAuthenticationContext();

  return user ? element : <Navigate to={LOGIN_PAGE_PATH} />;
};
