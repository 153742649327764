import { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
} from "@mui/material";
//Style
import {
  trainingItem,
  accordionStyle,
  accordionSummary,
  titleStyle,
  openVideoBtn,
  iconBox,
  addTrainingBtn,
  addBtnBox,
  addedTrainingBtn,
  fieldInput,
} from "./style";
//Assets
import { TopArrowIcon, DownArrowIcon } from "../../assets";
//Components
import { AddDescriptionToTraining } from "../addDescriptionToTraining";
import { VideoPopup } from "../../sections/userTrainingPartDetails/components/VideoPopup";
import { AddDescriptionPopup } from "../addDescriptionPopup";
import { AddDescriptionPreview } from "../addDescriptionPreview";
import { PredefinedNotesTraining } from "../predefinedNotesTraining";

type Props = {
  predefinedNotes: string;
  id?: string;
  title?: string;
  videoURL: string;
  category: string;
  isExercisePage?: boolean;
  onAddExerciseToUserHandler?: (exercise: any) => void;
  onRemoveExerciseToUserHandler?: (exerciseId: string) => void;
};

export const TrainingAccordionItem = ({
  predefinedNotes,
  id,
  title,
  videoURL,
  category,
  isExercisePage,
  onAddExerciseToUserHandler,
  onRemoveExerciseToUserHandler,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelectedTraining, setIsSelectedTraining] = useState(false);
  const [numOfSets, setNumOfSets] = useState("");
  const [weight, setWeight] = useState("");

  //Data
  const [additionalDescription, setAdditionalDescription] =
    useState<string>("");

  const onAddAdditionalDescriptionHandler = useCallback(
    (description: string) => {
      setAdditionalDescription(description);
    },
    []
  );
  //Popups - states
  const [isOpenAddDescriptionPopup, setIsOpenAddDescriptionPopup] =
    useState<boolean>(false);
  const [isOpenVideoPopup, setIsOpenVideoPopup] = useState<boolean>(false);

  const onToggleSelectedTraining = useCallback(() => {
    setIsSelectedTraining((prevState) => !prevState);
  }, [isSelectedTraining]);

  const onClickHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const onAddTraining = (e: any) => {
    e.stopPropagation();
    onToggleSelectedTraining();
  };

  const onVideoPopupToggle = useCallback(() => {
    setIsOpenVideoPopup((prevState) => !prevState);
  }, []);

  const onAddDescriptionPopupToggle = useCallback(() => {
    setIsOpenAddDescriptionPopup((prevState) => !prevState);
  }, []);

  const onAddExerciseHandler = () => {
    const exercise = {
      id,
      description: title,
      defaultDescription: predefinedNotes,
      category,
      videoURL,
      numOfSets,
      weight,
      specificDescription: additionalDescription,
    };
    if (onAddExerciseToUserHandler) onAddExerciseToUserHandler(exercise);
  };

  const onRemoveExerciseHandler = () => {
    if (onRemoveExerciseToUserHandler) onRemoveExerciseToUserHandler(id!);
  };

  return (
    <Box sx={trainingItem}>
      <VideoPopup isOpen={isOpenVideoPopup} onClose={onVideoPopupToggle} />
      <AddDescriptionPopup
        isOpen={isOpenAddDescriptionPopup}
        onClose={onAddDescriptionPopupToggle}
        onAdd={onAddAdditionalDescriptionHandler}
      />

      <Accordion
        sx={accordionStyle}
        expanded={isExpanded}
        onChange={onClickHandler}
      >
        <AccordionSummary sx={accordionSummary}>
          <Typography sx={titleStyle}>{title}</Typography>
          <Box sx={iconBox}>
            {!isExercisePage ? (
              <Box sx={addBtnBox} onClick={onAddTraining}>
                {isSelectedTraining ? (
                  <Button
                    onClick={onRemoveExerciseHandler}
                    sx={addedTrainingBtn}
                  >
                    Dodato
                  </Button>
                ) : (
                  <Button onClick={onAddExerciseHandler} sx={addTrainingBtn}>
                    Dodaj
                  </Button>
                )}
              </Box>
            ) : null}
            {isExpanded ? (
              <TopArrowIcon width="30" height="30" />
            ) : (
              <DownArrowIcon width="30" height="30" />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {!isExercisePage ? (
              <AddDescriptionToTraining onClick={onAddDescriptionPopupToggle} />
            ) : null}
            <TextField
              sx={fieldInput}
              label="Broj serija"
              value={numOfSets}
              onChange={(event) => setNumOfSets(event.target.value)}
              size="small"
            />
            <TextField
              sx={fieldInput}
              label="Kilaža"
              value={weight}
              onChange={(event) => setWeight(event.target.value)}
              size="small"
            />
          </Box>
          {additionalDescription && !isExercisePage ? (
            <AddDescriptionPreview description={additionalDescription} />
          ) : null}
          {predefinedNotes ? (
            <PredefinedNotesTraining predefinedNotes={predefinedNotes} />
          ) : null}
          <Box>
            <Button
              sx={openVideoBtn}
              variant="contained"
              onClick={onVideoPopupToggle}
            >
              Pogledaj video
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
