import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Config
import {
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  ADMIN_ROLE,
  USER_ROLE,
  USER_NAV_ITEMS_HOME_PAGE,
  TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH,
  USER_HISTORY_OVERVIEW_PAGE,
  CUSTOMER,
  TRAINING,
  HISTORY,
  EXERCISES,
  SURVEY_PAGE_PATH,
  ADD,
  EDIT_PATH,
} from "./config";
//Components
import {
  LoginPage,
  CustomerAccountsPage,
  UserTrainingDetailPage,
  UserHistoryOverviewPage,
  UserHistoryListForSpecificMonthPage,
  CustomerDetailPage,
  CustomerAccountHistory,
  CustomerAccHistoryForSpecificMonth,
  CustomerAccHistoryForSpecificDay,
  ExercisesPage,
  SurveyPage,
  AddNewExercise,
  CustomerDetailSurveyPage,
  EditCustomerPage,
} from "./pages";
import { CustomerAddTrainingPage } from "./pages/admin/customerAddTrainingPage/CustomerAddTrainingPage";
import { UserHomePage } from "./pages/user/userHomePage/UserHomePage";
//Context
import { useAuthenticationContext } from "./context";
//Config
import { ADMIN_NAV_ITEMS } from "./config";
import { PrivateRoute } from "./HOC";

function App() {
  const { role, user } = useAuthenticationContext();
  const getHomePage = () => {
    if (role === ADMIN_ROLE)
      return <CustomerAccountsPage navItems={ADMIN_NAV_ITEMS} />;
    if (role === USER_ROLE)
      return <UserHomePage navItems={USER_NAV_ITEMS_HOME_PAGE} />;

    return <CustomerAccountsPage navItems={ADMIN_NAV_ITEMS} />;
  };

  return (
    <Router>
      <Routes>
        <Route
          path={HOME_PAGE_PATH}
          element={<PrivateRoute element={getHomePage()} />}
        />
        <Route path={LOGIN_PAGE_PATH} element={<LoginPage />} />
        <Route path={SURVEY_PAGE_PATH} element={<SurveyPage />} />

        {/* USER*/}
        {role === USER_ROLE ? (
          <Route
            path={`${TRAINING_FOR_SPECIFIC_DAY_PAGE_PATH}/:day/:trainingId`}
            element={
              <PrivateRoute
                element={
                  <UserTrainingDetailPage navItems={USER_NAV_ITEMS_HOME_PAGE} />
                }
              />
            }
          />
        ) : null}
        <Route
          path={USER_HISTORY_OVERVIEW_PAGE}
          element={
            <PrivateRoute
              element={
                <UserHistoryOverviewPage navItems={USER_NAV_ITEMS_HOME_PAGE} />
              }
            />
          }
        />
        <Route
          path={`${USER_HISTORY_OVERVIEW_PAGE}/:month`}
          element={
            <PrivateRoute
              element={
                <UserHistoryListForSpecificMonthPage
                  navItems={USER_NAV_ITEMS_HOME_PAGE}
                />
              }
            />
          }
        />

        {/*ADMIN */}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}/:id`}
            element={
              <PrivateRoute
                element={<CustomerDetailPage navItems={ADMIN_NAV_ITEMS} />}
              />
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${EDIT_PATH}/:customerId`}
            element={
              <PrivateRoute
                element={<EditCustomerPage navItems={ADMIN_NAV_ITEMS} />}
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}${SURVEY_PAGE_PATH}/:id`}
            element={
              <PrivateRoute
                element={
                  <CustomerDetailSurveyPage navItems={ADMIN_NAV_ITEMS} />
                }
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}/${TRAINING}/:id`}
            element={
              <PrivateRoute
                element={<CustomerAddTrainingPage navItems={ADMIN_NAV_ITEMS} />}
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}/${HISTORY}/:id`}
            element={
              <PrivateRoute
                element={<CustomerAccountHistory navItems={ADMIN_NAV_ITEMS} />}
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}/${HISTORY}/:id/:month`}
            element={
              <PrivateRoute
                element={
                  <CustomerAccHistoryForSpecificMonth
                    navItems={ADMIN_NAV_ITEMS}
                  />
                }
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={`${CUSTOMER}/:id/${TRAINING}/:trainingId`}
            element={
              <PrivateRoute
                element={
                  <CustomerAccHistoryForSpecificDay
                    navItems={ADMIN_NAV_ITEMS}
                  />
                }
              ></PrivateRoute>
            }
          />
        ) : null}
        {role === ADMIN_ROLE ? (
          <Route
            path={EXERCISES}
            element={
              <PrivateRoute
                element={<ExercisesPage navItems={ADMIN_NAV_ITEMS} />}
              ></PrivateRoute>
            }
          />
        ) : null}

        {role === ADMIN_ROLE ? (
          <Route
            path={`${EXERCISES}/${ADD}`}
            element={
              <PrivateRoute
                element={<AddNewExercise navItems={ADMIN_NAV_ITEMS} />}
              ></PrivateRoute>
            }
          />
        ) : null}
      </Routes>
    </Router>
  );
}

export default App;
