import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
//Components
import { Layout } from "../../../layout";
import { SelectorTrainingBar, InfoBox } from "./components";
import { UserTrainingPartDetails } from "../../../sections";
import { BackButton } from "../../../components";
//Style
import { userTrainingDetailPage, pageHeader } from "./style";
//Config
import { useGetTrainingById } from "../../../hooks";
import { useParams } from "react-router-dom";

type Props = {
  navItems: { title: string; path: string }[];
};

export const UserTrainingDetailPage = ({ navItems }: Props) => {
  const data = ["Zagrevanje", "Trening", "Istezanje"];
  const { trainingId } = useParams();
  const { training } = useGetTrainingById(trainingId!);

  const [selectedTraining, setSelectedTraining] = useState<string>(data[0]);

  const onSelectTrainingHandler = useCallback(
    (partOfTraining: string) => {
      setSelectedTraining(partOfTraining);
    },
    [selectedTraining]
  );

  const detailTrainingPartData = training?.exercises.filter(
    (item) => item.category === selectedTraining
  );

  return (
    <Layout userNavItems={navItems}>
      <Box sx={userTrainingDetailPage}>
        <Box sx={pageHeader}>
          <SelectorTrainingBar
            data={data}
            selectedTraining={selectedTraining}
            onClick={onSelectTrainingHandler}
          />
        </Box>

        {detailTrainingPartData && detailTrainingPartData?.length > 0 ? (
          <UserTrainingPartDetails data={detailTrainingPartData} />
        ) : (
          <Typography>
            Nema dostupnih treninga iz sekcije {selectedTraining}
          </Typography>
        )}
      </Box>
    </Layout>
  );
};
